import React from 'react';
import {Drawer, Timeline} from 'antd';
import {Order} from "../models/Order";
import moment from "moment";
import {History} from "../models/History";
import ImageHistoryContainer from "../../SmuAssignOrder/components/ImageHistoryContainer";

interface HistoryDrawerSchema {
    open: boolean;
    setOpen: (open: boolean) => void;
    order: Order;
    token: string;
}

const HistoryDrawer = ({ open, setOpen, order, token }: HistoryDrawerSchema): JSX.Element => {

    const onClose = () => {
        setOpen(false);
    };

    const getItems = (): History[] => {
        return order.history.sort(function(a,b){return b.date.getTime() - a.date.getTime()});
    }

    return (
        <Drawer
            title={`Historial | # Orden: ${order.orderClient}`}
            // width={450}
            width={720}
            onClose={onClose}
            open={open}
            bodyStyle={{ paddingBottom: 80 }}
            destroyOnClose
        >
            <Timeline mode="left" className="ant-timeline-label">
                {
                    getItems().map((history, idx) => {
                        return (
                            <Timeline.Item label={moment(history.date).format('DD/MM/YYYY HH:mm:ss')} key={idx}>
                                { history.status.charAt(0).toUpperCase() + history.status.slice(1) }
                                <>
                                    <ImageHistoryContainer s3key={history.image1} token={token}/>
                                    <ImageHistoryContainer s3key={history.image2} token={token}/>
                                    <ImageHistoryContainer s3key={history.image3} token={token}/>
                                </>
                            </Timeline.Item>

                        );
                    })
                }
            </Timeline>
        </Drawer>
    );
};

export default HistoryDrawer;