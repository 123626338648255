import React, { useState } from 'react';
import moment from 'moment';
import { ProCard } from "@ant-design/pro-components";
import AuthAction from "../../redux/actions/authActions/actions";
import SearchFindUser from "./services/SearchService";
import AppLayout from "../../components/Layouts/App/App";
import { FormLayout } from "antd/es/form/Form";
import { Button, Checkbox, Form, Input, message, Spin, DatePicker, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import './registerU.css';
import { Col, Row, Space } from 'antd';
const LAYOUT_TYPE_HORIZONTAL = 'horizontal';



const waitTime = (time: number = 100) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};


//Documentación: https://procomponents.ant.design/en-US/components/form

const FindUser = (): JSX.Element => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const onFinish = async (values: any) => {
        if (!loading) {
            const sendBody = {
                first_name: values.nombres,
                surname: values.apellidos,
                second_surname: values.nombres,
                rut: values.rut,
                nationality: values.cmbNacionalidad,
                gender: values.cmbGenero,
                civil_status: values.cmbEstadoCivil,
                birthday: values.fechnac ? moment(values.fechnac?.['$d']).format('YYYY-MM-DD') : '',
                email: values.email,
                personal_email: values.email,
                location_id: 45,
                address: values.direccion,
                city: values.cmbCiudad,
                payment_method: values.cmbPago,
                bank: values.cmbBanco,
                account_type: values.cmbCuenta
            }
            const loadingMessageKey = 'loading-message';
            messageApi.open({
                key: loadingMessageKey,
                type: 'loading',
                content: 'Search user...',
                duration: 0
            });
            setLoading(true);

            const searchResponse = await SearchFindUser.findUser(values.rut);

            setLoading(false);

            messageApi.destroy(loadingMessageKey);

            if (searchResponse.success) {
                console.log(searchResponse.data![0])
                if (searchResponse.data![0]) {
                    const values: any = searchResponse.data![0];
                    form.setFieldValue('nombres', values.first_name)
                    form.setFieldValue('apellidos', values.second_surname)
                    form.setFieldValue('cmbGenero', values.gender === "M" ? "Masculino" : "Femenino")
                    form.setFieldValue('cmbNacionalidad', values.nationality)
                    form.setFieldValue('email', values.personal_email)
                    form.setFieldValue('cmbEstadoCivil', values.civil_status)
                    form.setFieldValue('direccion', values.address)
                    form.setFieldValue('cmbCiudad', values.city)
                    form.setFieldValue('cmbPago', values.payment_method)
                    form.setFieldValue('cmbBanco', values.bank)
                    form.setFieldValue('cmbCuenta', values.account_type)
                    form.setFieldValue('fechnac', values.birthday)


                }

                // messageApi.success('Autenticó sus credenciales con éxito.', 3.5);
                // dispatch(AuthAction.signIn(searchResponse.data));
                // navigate('/');
            } else {
                messageApi.error(searchResponse.error?.data?.message || 'Hubo un error al crear al usuario, por favor inténtalo nuevamente.', 3.5);
            }
        }
    };
    const cleanForm = () => {
        form.resetFields();
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        messageApi.error('Por favor corrija los errores mencionados', 3.5);
    };
    return (
        <>
            {contextHolder}
            <AppLayout title="Buscar Usuario">
                <ProCard
                    style={{
                        minHeight: 350,
                        paddingLeft: '15%'
                    }}
                >
                    <Form
                        form={form}
                        name="login-form-rg"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >

                        <Form.Item
                            name="rut"
                            rules={[
                                { required: true, message: 'El Rut es requerido' },
                            ]}
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Rut"
                            />
                        </Form.Item>
                        <Form.Item
                            className='buttons-style'
                        >
                            <Button type="primary" htmlType="submit" className="login-form-button-rg">
                                BUSCAR
                            </Button>
                        </Form.Item>
                        <Form.Item
                            className='buttons-style'>
                            <Button type="primary" onClick={cleanForm} className="login-form-button-rg">
                                Limpiar
                            </Button>
                        </Form.Item>
                        <Form.Item
                            name="nombres"
                        >
                            <Input
                                disabled={true}
                                className='login-form-rg-text'
                                placeholder="Nombres"
                            />
                        </Form.Item>

                        <Form.Item
                            name="apellidos"
                        >
                            <Input
                                disabled={true}
                                className='login-form-rg-text'
                                placeholder="Apellidos"
                            />
                        </Form.Item>


                        <Form.Item
                            name="email"

                        >
                            <Input
                                disabled={true}
                                className='login-form-rg-text'
                                placeholder="Email"
                            />
                        </Form.Item>
                        <Form.Item
                            name="direccion"
                        >
                            <Input
                                disabled={true}
                                className='login-form-rg-text'
                                placeholder="Direccion"

                            />
                        </Form.Item>
                        <Form.Item
                            name="fechnac"

                        >
                            <Input
                                disabled={true}
                                className='login-form-rg-text'
                                placeholder="Fecha de nacimiento"

                            />

                        </Form.Item>
                        <Form.Item
                            name='cmbNacionalidad'
                        >
                            <Input
                                disabled={true}
                                className='login-form-rg-text'
                                placeholder="Nacionalidad"

                            />
                        </Form.Item>

                        <Form.Item
                            name='cmbCiudad'
                        >
                            <Input
                                disabled={true}
                                className='login-form-rg-text'
                                placeholder="Ciudad"

                            />
                        </Form.Item>


                        <Form.Item
                            name='cmbGenero'
                        >
                            <Input
                                disabled={true}
                                className='login-form-rg-text'
                                placeholder="Genero"

                            />
                        </Form.Item>

                        <Form.Item
                            name='cmbEstadoCivil'
                        >
                            <Input
                                disabled={true}
                                className='login-form-rg-text'
                                placeholder="Estado Civil"

                            />
                        </Form.Item>



                        <Form.Item
                            name='cmbPago'
                        >
                            <Input
                                disabled={true}
                                className='login-form-rg-text'
                                placeholder="Metodo de Pago"

                            />
                        </Form.Item>

                        <Form.Item
                            name='cmbBanco'
                        >
                            <Input
                                disabled={true}
                                className='login-form-rg-text'
                                placeholder="Banco"

                            />
                        </Form.Item>

                        <Form.Item
                            name='cmbCuenta'
                        >
                            <Input
                                disabled={true}
                                className='login-form-rg-text'
                                placeholder="Cuenta"

                            />
                        </Form.Item>



                    </Form>
                </ProCard>
            </AppLayout >
        </>
    );
};

export default FindUser;