import User from "../../../models/User";
import { config } from "../../../config/config";
import axios from "axios";
import { Permission } from "../../../models/Permission";

const api = axios.create({
    baseURL: 'https://integraciones-labs.titask.com/api/integrations/v1/',
    headers: {
        "Accept": 'application/json',
        "Content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "Authorization": "R4qLIFZLICafC4WQzEwLhBNUvKO",
        "country": "CL",
        "lang": "es"
    },
})

export const AuthService = {
    login: async (email: string, password: string) => {
        try {
            const response = await api.post('/app-auth/login', { email, password });
            const userData = response.data.data;

            return {
                success: true,
                data: {
                    id: userData.id,
                    appRoleId: userData.appRoleId,
                    email: userData.email,
                    name: userData.name,
                    lastname: userData.lastname,
                    documentType: userData.documentType,
                    documentNumber: userData.documentNumber,
                    platform: userData.platform,
                    plateNumber: userData.plateNumber,
                    token: userData.token,
                    permissions: userData.permissions.map((permission: any): Permission => {
                        return {
                            id: permission.id,
                            titleEn: permission.titleEn,
                            titleEs: permission.titleEs,
                            platform: permission.platform,
                            description: permission.description
                        };
                    }),
                    carriers: !userData.carriers ? [] : userData.carriers.map((carrier: any) => carrier.id)
                } as User
            }
        } catch (err: any) {
            return {
                success: false,
                error: err?.response?.data
            }
        }
    },
    saveUser: async (body: any) => {
        try {
            const response = await api.post('buk/addEmploye', body);
            const userData = response.data.data;
            return {
                success: true,
                data: userData
            }
        } catch (err: any) {
            return {
                success: false,
                error: err?.response?.data
            }
        }
    },
};