import React, {useState} from 'react';
import {ProCard} from "@ant-design/pro-components";
import AppLayout from "../../components/Layouts/App/App";
import {FormLayout} from "antd/es/form/Form";
import {
    ProForm,
    ProFormDatePicker,
    ProFormDateRangePicker,
    ProFormDigit,
    ProFormRadio,
    ProFormSelect,
    ProFormSwitch,
    ProFormText,
    ProFormTextArea,
} from '@ant-design/pro-components';
import { Col, message, Row, Space } from 'antd';

const LAYOUT_TYPE_HORIZONTAL = 'horizontal';

const waitTime = (time: number = 100) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};

//Documentación: https://procomponents.ant.design/en-US/components/form

const FormExample = (): JSX.Element => {
    const [formLayoutType, setFormLayoutType] = useState<FormLayout>(
        LAYOUT_TYPE_HORIZONTAL,
    );

    const [grid, setGrid] = useState(true);

    return (
        <>
            <AppLayout title="Formulario (ejemplo)">
                <ProCard
                    style={{
                        minHeight: 350,
                    }}
                >
                    <ProForm<{
                        name: string;
                        company?: string;
                        useMode?: string;
                    }>
                        layout={formLayoutType}
                        grid={grid}
                        rowProps={{
                            gutter: [16, formLayoutType === 'inline' ? 16 : 0],
                        }}
                        submitter={{
                            render: (props, doms) => {
                                return formLayoutType === LAYOUT_TYPE_HORIZONTAL ? (
                                    <Row>
                                        <Col span={14} offset={4}>
                                            <Space>{doms}</Space>
                                        </Col>
                                    </Row>
                                ) : (
                                    doms
                                );
                            },
                        }}
                        onFinish={async (values) => {
                            await waitTime(2000);
                            console.log(values);
                            message.success('Submit realizado');
                        }}
                        params={{}}
                        request={async () => {
                            //Puedes traer data desde una api y setear datos del formulario
                            await waitTime(100);
                            return {
                                name: 'Texto cargado desde una api',
                                useMode: 'chapter',
                            };
                        }}
                    >
                        <ProFormRadio.Group
                            label="Tipo de formulario"
                            radioType="button"
                            fieldProps={{
                                value: formLayoutType,
                                onChange: (e) => setFormLayoutType(e.target.value),
                            }}
                            colProps={{
                                span: 20,
                            }}
                            options={['horizontal', 'vertical', 'inline']}
                        />
                        <ProFormSwitch
                            colProps={{
                                span: 4,
                            }}
                            fieldProps={{
                                onChange: setGrid,
                            }}
                            initialValue={true}
                            label="Checkbox"
                            name="grid"
                        />
                        <ProFormText
                            name="name"
                            label="Label 1"
                            tooltip="Ejemplo de texto tooltip"
                            placeholder="Placeholder ejemplo"
                        />
                        <ProFormText colProps={{ md: 12, xl: 8 }} name="company" label="Compañía" />
                        <ProFormDigit colProps={{ md: 12, xl: 8 }} name="phone" label="Teléfono" />
                        <ProFormText colProps={{ md: 12, xl: 8 }} name="email" label="Correo eléctronico" />
                        <ProFormTextArea
                            colProps={{ span: 24 }}
                            name="address"
                            label="Dirección"
                        />
                        <ProFormDatePicker
                            colProps={{ xl: 8, md: 12 }}
                            label="Fecha de nacimiento"
                            name="date"
                        />
                        <ProFormDateRangePicker
                            colProps={{ xl: 8, md: 12 }}
                            label="Fechas disponibles"
                            name="dateRange"
                        />
                        <ProFormSelect
                            colProps={{ xl: 8, md: 12 }}
                            label="Nivel"
                            name="level"
                            valueEnum={{
                                1: 'front end',
                                2: 'back end',
                                3: 'full stack',
                            }}
                        />
                    </ProForm>
                </ProCard>
            </AppLayout>
        </>
    );
};

export default FormExample;