import React from 'react';
import {Modal} from "antd";
import {ProForm, ProFormSelect, ProFormText} from "@ant-design/pro-components";
import User from "../../../models/User";
import {Carrier} from "../../../models/Carrier";

const documentTypes = [
    {
        value: 'RUT',
        label: 'RUT'
    },
    {
        value: 'DNI',
        label: 'DNI'
    },
    {
        value: 'CE',
        label: 'Carnet de extranjería'
    }
];

interface EditDriverModalSchema {
    driver: User;
    open: boolean;
    submit: (id: number, name: string, lastname: string, documentType: string, documentNumber: string, plateNumber: string, carriers: string[]) => void;
    setOpen: (value: boolean) => void;
    disabled: boolean;
    carriers: Carrier[];
}

const EditDriverModal = ({driver, open, submit, setOpen, disabled, carriers}: EditDriverModalSchema): JSX.Element => {
    const getStores = () => {
        return (carriers || []).map((carrier) => {
            return {
                value: carrier.id,
                label: carrier.name
            }
        });
    }

    return (
        <Modal
            title={`${disabled ? 'Detalles': 'Editar'} driver (ID: ${driver.id})`}
            open={open}
            destroyOnClose
            onCancel={() => { setOpen(false) }}
            closable
            keyboard
            footer={null}
        >
            <hr style={{ border: '0', borderTop: '1px solid #c3c3c3'}}/>

            <ProForm<any>
                style={{ marginTop: '20px' }}
                onFinish={async (values: any) => {
                    if(!disabled) {
                        await submit(driver.id, values.name_input, values.lastname_input, values.document_type_input, values.document_number_input, values.plate_number_input, values.carriers_input || []);
                    }
                }}
                submitter={{
                    searchConfig: {
                        submitText: 'Guardar cambios'
                    },
                    resetButtonProps: {
                        style: {
                            display: 'none',
                        },
                    },
                    submitButtonProps: {
                        style: {
                            marginLeft: '-8px',
                            display: disabled ? 'none' : 'block',
                        },
                    },
                }}
            >
                <ProForm.Group>
                    <ProFormText
                        name="email_input"
                        label="Email"
                        width="xl"
                        tooltip="El email no debe de estar actualmente en uso por nuestro sistema"
                        placeholder="Ingrese un email"
                        disabled={true}
                        initialValue={driver.email}
                    />

                    <ProFormText
                        name="name_input"
                        label="Nombres"
                        width="xl"
                        placeholder="Ingrese los nombres"
                        rules={[{ required: true, message: 'Debe de ingresar los nombres' }, { min: 3, message: 'Los nombres deben de tener al menos 3 caracteres' }]}
                        initialValue={driver.name}
                        disabled={disabled}
                    />

                    <ProFormText
                        name="lastname_input"
                        label="Apellidos"
                        width="xl"
                        placeholder="Ingrese los apellidos"
                        rules={[{ required: true, message: 'Debe de ingresar los apellidos' }, { min: 3, message: 'Los apellidos deben de tener al menos 3 caracteres' }]}
                        initialValue={driver.lastname}
                        disabled={disabled}
                    />

                    <ProFormSelect
                        options={documentTypes}
                        width="xl"
                        name="document_type_input"
                        label="Tipo de documento"
                        placeholder="Seleccione el tipo de documento"
                        rules={[{ required: true, message: 'Debe de seleccionar el tipo de documento' }]}
                        initialValue={driver.documentType}
                        disabled={disabled}
                    />

                    <ProFormText
                        name="document_number_input"
                        label="Número de documento"
                        width="xl"
                        placeholder="Ingrese el número de documento"
                        rules={[{ required: true, message: 'Debe de ingresar el número de documento' }, { min: 5, message: 'El número de documento de tener al menos 5 caracteres' }]}
                        initialValue={driver.documentNumber}
                        disabled={disabled}
                    />

                    <ProFormText
                        name="plate_number_input"
                        label="Número de placa"
                        width="xl"
                        placeholder="Ingrese el número de documento"
                        rules={[{ required: true, message: 'Debe de ingresar el número de placa' }, { min: 4, message: 'El número de placa de tener al menos 4 caracteres' }]}
                        initialValue={driver.plateNumber}
                        disabled={disabled}
                    />

                    <ProFormSelect
                        options={getStores()}
                        width="xl"
                        name="carriers_input"
                        label="Tiendas asignadas"
                        placeholder="-SELECCIONE-"
                        mode="multiple"
                        rules={[{ required: true, message: 'Debe de seleccionar al menos una tienda' }]}
                        initialValue={driver.carriers}
                        disabled={disabled}
                    />
                </ProForm.Group>
            </ProForm>
        </Modal>
    );
};

export default EditDriverModal;