import {
    FileDoneOutlined,
    HomeOutlined,
    DropboxOutlined,
    SettingOutlined,
    TeamOutlined,
    ProfileOutlined
} from "@ant-design/icons";
import React from "react";
import User from "../models/User";

const dashboardMenu = (user?: User) => {

    let routes = [
        {
            path: '/home',
            name: 'Home',
            icon: <HomeOutlined />,
        },
        {
            path: '/smu-orders',
            name: 'Órdenes',
            icon: <DropboxOutlined />,
            permission: 'SHOW_SMU_ORDER_HISTORY_MODULE'
        },
        {
            path: '/smu-assign-order',
            name: 'Asignar orden',
            icon: <FileDoneOutlined />,
            permission: 'SHOW_SMU_ASSIGN_ORDER_MODULE'
        },
        {
            path: '/drivers',
            name: 'Drivers',
            icon: <SettingOutlined />,
            permission: 'SHOW_SMU_DRIVER_MAINTENANCE_MODULE'
        },
        {
            path: '/users',
            name: 'Usuarios',
            icon: <TeamOutlined />,
            permission: 'SHOW_SMU_USERS_MAINTENANCE_MODULE'
        },
        // {
        //     path: '/form-example',
        //     name: 'Formulario (ejemplo)',
        //     icon: <ProfileOutlined />,
        // },
        {
            path: '/create-user',
            name: 'Usuarios',
            icon: <ProfileOutlined />,
        },
        {
            path: '/plan-user',
            name: 'Plan Usuario',
            icon: <ProfileOutlined />,
        },
        {
            path: '/job-user',
            name: 'Trabajo Usuario',
            icon: <ProfileOutlined />,
        },
        {
            path: '/up-user',
            name: 'Up Users',
            icon: <ProfileOutlined />,
        },

        {
            path: '/search-user',
            name: 'Search User',
            icon: <ProfileOutlined />,
        },
        {
            path: '/search-files',
            name: 'Files',
            icon: <ProfileOutlined />,
        },
        user?.appRoleId === "ADMIN_BUK" ? {
            path: '/black-list',
            name: 'Black List',
            icon: <ProfileOutlined />,
        } : {},


    ];

    routes = routes.filter((route: any) => {
        const permissions: any[] = user ? (user.permissions ? user.permissions.map((permission) => permission.id) : []) : [];
        return !route.permission || permissions.includes(route.permission);
    });

    return {
        routes: [
            {
                path: '/',
                name: 'Touch Jobs',
                routes: routes
            }
        ]
    };
};

export default dashboardMenu;