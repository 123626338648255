import React, { useState } from 'react';
import Password from "antd/es/input/Password";
import { Button, Checkbox, Form, Input, message, Spin } from "antd";
import { AuthService } from "./services/AuthService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import AuthAction from "../../redux/actions/authActions/actions";

import './login.css';

const Index = (): JSX.Element => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const onFinish = async (values: any) => {
        if (!loading) {
            const loadingMessageKey = 'loading-message';
            messageApi.open({
                key: loadingMessageKey,
                type: 'loading',
                content: 'Comprobando credenciales...',
                duration: 0
            });
            setLoading(true);

            const loginResponse = await AuthService.login(values.username, values.password);

            setLoading(false);

            messageApi.destroy(loadingMessageKey);

            if (loginResponse.success) {
                messageApi.success('Autenticó sus credenciales con éxito.', 3.5);
                dispatch(AuthAction.signIn(loginResponse.data));
                navigate('/');
            } else {
                messageApi.error(loginResponse.error?.data?.message || 'Hubo un error al intentar autenticar al usuario, por favor inténtalo nuevamente.', 3.5);
            }
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        messageApi.error('Por favor corrija los errores mencionados', 3.5);
    };

    return (
        <div className="login-page">
            {contextHolder}
            <Spin spinning={loading}>
                <div className="login-box">
                    <div className="illustration-wrapper">
                        <img src="https://titaskdomain.s3.us-east-2.amazonaws.com/files/images/mixkit-left-handed-man-sitting-at-a-table-writing-in-a-notebook-27-original-large.avif" alt="Login" />
                    </div>
                    <Form
                        name="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <p className="form-title">Touch Jobs App</p>
                        <p>Acceso al panel de control</p>
                        <Form.Item
                            name="username"
                            rules={[
                                { required: true, message: 'El correo electrónico es requerido' },
                                { type: 'email', message: 'Debe de ingresar un correo electrónico válido' },

                            ]}
                        >
                            <Input
                                placeholder="Username"
                            />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                { required: true, message: 'La contraseña es requerida' },
                                { min: 4, message: 'La contraseña debe de tener un mínimo de 4 caracteres' },
                            ]}
                        >
                            <Password
                                placeholder="Password"
                            />
                        </Form.Item>

                        <Form.Item name="remember" valuePropName="checked">
                            <Checkbox>Recuérdame</Checkbox>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                INICIAR SESIÓN
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </div>
    );
};

export default Index;