import axios from "axios";
import {config} from "../../../config/config";
import User from "../../../models/User";
import {AvailableOrder} from "../../SmuAssignOrder/models/AvailableOrder";
import {Carrier} from "../../../models/Carrier";

const api = axios.create({
    baseURL: config.API_BFF_TITASK_APP,
    headers: {
        "Accept": 'application/json',
        "Content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "lang": "es"
    },
});

const apiIntegrationsComunidad = axios.create({
    baseURL: config.API_INTEGRACIONES_COMUNIDADTASK_URI,
    headers: {
        "Accept": 'application/json',
        "Content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*"
    },
});

export const UserService = {
    findUserById: async (token: string, id: number) => {
        try {
            const response = await api.get(`/app-user/find-app-user/${id}`,
                {  headers: { Authorization: `Bearer ${token}` },
                });

            const userRawData = response.data.data;

            return {
                success: true,
                data: {
                    id: userRawData.id,
                    appRoleId: userRawData.appRoleId,
                    email: userRawData.email,
                    name: userRawData.name,
                    lastname: userRawData.lastname,
                    documentType: userRawData.documentType,
                    documentNumber: userRawData.documentNumber,
                    platform: userRawData.platform,
                    plateNumber: userRawData.plateNumber,
                    token: userRawData.token,
                    carriers: !userRawData.carriers ? [] : userRawData.carriers.map((carrier: any) => carrier.id)
                } as User
            }
        } catch (err: any) {
            return {
                success: false,
                error: err
            }
        }
    },
    getSmuUsers: async (token: string) => {
        try {
            const response = await api.post(`/app-user/get-smu-users`,
                { roleIds: ['ADMIN_SMU', 'CONTROL_TOWER_SMU'] },
                {  headers: { Authorization: `Bearer ${token}` },
                });

            const driversRawData = response.data.data;

            return {
                success: true,
                data: driversRawData.map((driverRawData: any): User => {
                    return {
                        id: driverRawData.id,
                        appRoleId: driverRawData.appRoleId,
                        email: driverRawData.email,
                        name: driverRawData.name,
                        lastname: driverRawData.lastname,
                        documentType: driverRawData.documentType,
                        documentNumber: driverRawData.documentNumber,
                        platform: driverRawData.platform,
                        plateNumber: driverRawData.plateNumber,
                        token: driverRawData.token,
                        carriers: []
                    }
                })
            }
        } catch (err: any) {
            return {
                success: false,
                error: err
            }
        }
    },
    getCarriers: async () => {
        try {
            const response = await apiIntegrationsComunidad.get(`/clients/smu/carrier`);
            const carriersRaw = response.data?.carriers || [];

            return {
                success: true,
                data: carriersRaw.map((carrierRaw: any): Carrier => {
                    return {
                        id: carrierRaw.carrierId,
                        status: carrierRaw.status,
                        name: carrierRaw.name,
                        address: carrierRaw.address,
                        addressTwo: carrierRaw.address_two,
                        description: carrierRaw.description,
                        country: carrierRaw.country,
                        city: carrierRaw.city,
                        state: carrierRaw.state,
                        zipCode: carrierRaw.zip_code,
                        latitude: carrierRaw.latitude,
                        longitude: carrierRaw.longitude,
                        slack: carrierRaw.slack
                    }
                })
            }
        }catch (err)
        {
            return {
                success: false,
                error: err
            }
        }
    },
    createSmuUser: async (token: string, email: string, name: string, lastname: string, roleId: 'ADMIN_SMU' | 'CONTROL_TOWER_SMU', carriers: string[]) => {
        try {
            const response = await api.post(`/app-user/create-smu-user`,
                { email, name, lastname, documentType: null, documentNumber: null, plateNumber: null, roleId, carriers },
                {  headers: { Authorization: `Bearer ${token}` },
                });

            return {
                success: true,
                data: response.data.data
            }
        } catch (err: any) {
            return {
                success: false,
                error: err
            }
        }
    },
    updateSmuUser: async (token: string, id: number, name: string, lastname: string, roleId: string, carriers: string[]) => {
        try {
            const response = await api.put(`/app-user/update-smu-user/${id}`,
                { name, lastname, documentType: null, documentNumber: null, plateNumber: null, roleId, carriers },
                {  headers: { Authorization: `Bearer ${token}` },
                });

            return {
                success: true,
                data: response.data.data
            }
        } catch (err: any) {
            return {
                success: false,
                error: err
            }
        }
    },
}