import React, { useState } from 'react';
import moment from 'moment';
import { ProCard } from "@ant-design/pro-components";
import AuthAction from "../../redux/actions/authActions/actions";
import UserService from "./services/UserService";
import AppLayout from "../../components/Layouts/App/App";
import { FormLayout } from "antd/es/form/Form";
import { Button, Checkbox, Form, Input, message, Spin, DatePicker, Select, UploadProps, UploadFile } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import './registerU.css';
import { Col, Row, Space } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
const LAYOUT_TYPE_HORIZONTAL = 'horizontal';



const waitTime = (time: number = 100) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};


//Documentación: https://procomponents.ant.design/en-US/components/form

const UpUser = (): JSX.Element => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false); 
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const onFinish = async (values: any) => {
        if (!loading) {
            const sendBody = {
                first_name: values.nombres,
                surname: values.apellidos,
                second_surname: values.nombres,
                rut: values.rut,
                nationality: values.cmbNacionalidad,
                gender: values.cmbGenero,
                civil_status: values.cmbEstadoCivil,
                birthday: values.fechnac ? moment(values.fechnac?.['$d']).format('YYYY-MM-DD') : '',
                email: values.email,
                personal_email: values.email,
                location_id: 45,
                address: values.direccion,
                city: values.cmbCiudad,
                payment_method: values.cmbPago,
                bank: values.cmbBanco,
                account_type: values.cmbCuenta
            }
            const loadingMessageKey = 'loading-message';
            messageApi.open({
                key: loadingMessageKey,
                type: 'loading',
                content: 'Registrado user...',
                duration: 0
            });
            setLoading(true);

            const loginResponse = await UserService.saveUser(sendBody);

            setLoading(false);

            messageApi.destroy(loadingMessageKey);

            if (loginResponse.success) {
                messageApi.success('Se registro con ex.', 3.5);
                dispatch(AuthAction.signIn(loginResponse.data));
                // navigate('/');
            } else {
                messageApi.error(loginResponse.error?.data?.message || 'Hubo un error al crear al usuario, por favor inténtalo nuevamente.', 3.5);
            }
        }
    };
    const cleanForm = () => {
        form.resetFields();
    }
    const handleUpload = async () => {
        const loadingMessageKey = 'loading-message-valor';
        setUploading(true);
        setLoading(true);
        messageApi.open({
            key: loadingMessageKey,
            type: 'loading',
            content: 'Upload Files...',
            duration: 0
        });
        for (const file of fileList) {
            const formData = new FormData();
            formData.append('file', file as RcFile);
            const searchResponse: any = await UserService.uploadFile(formData);
            console.log(searchResponse)
            if (searchResponse?.success) {
                messageApi.success('Se cargo correctamente', 3.5);
            }
        }
    
        setLoading(false);
        setUploading(false);
        messageApi.destroy(loadingMessageKey);
    };
    
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        messageApi.error('Por favor corrija los errores mencionados', 3.5);
    };

    const props: UploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };
    return (
        <>
            {contextHolder}
            <AppLayout title="Cargar Usuarios">
                <ProCard
                    style={{
                        minHeight: 350,
                        paddingLeft: '15%'
                    }}
                >
                    <Form
                        form={form}
                        name="login-form-rg"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >



                        <br />

                        <p>Plantiall para cargar Usuarios.<a href="https://titaskdomain.s3.us-east-2.amazonaws.com/files/Example_register.xlsx">Descargar</a></p>
                        <br></br>
                        <Dragger {...props} >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Cargar Excel de Usuarios</p>
                            <p className="ant-upload-hint">
                                Revisar previamente sus archivos por seguridad de los usuarios.
                            </p>
                        </Dragger>
                        <Button type="primary" loading={loading} onClick={handleUpload} className="login-form-button-rg">
                            Cargar Usuarios
                        </Button>



                    </Form>
                </ProCard>
            </AppLayout >
        </>
    );
};

export default UpUser;