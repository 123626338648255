import User from "../../../models/User";
import { config } from "../../../config/config";
import axios from "axios";
import { Permission } from "../../../models/Permission";

const api = axios.create({
    baseURL: 'https://integraciones-labs.titask.com/api/integrations/v1/',
    headers: {
        "Accept": 'application/json',
        "Content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "Authorization": "R4qLIFZLICafC4WQzEwLhBNUvKO",
        "country": "CL",
        "lang": "es"
    },
})

const SearchFindUser = {

    findUser: async (body: any) => {
        try {
            const response = await api.get(`buk/employe/${body}`, body);
            const userData = response.data.data;
            return {
                success: true,
                data: userData
            }
        } catch (err: any) {
            return {
                success: false,
                error: err?.response?.data
            }
        }
    },
    saveUser: async (body: any) => {
        try {
            const response = await api.post('buk/addEmploye', body);
            const userData = response.data.data;
            return {
                success: true,
                data: userData
            }
        } catch (err: any) {
            return {
                success: false,
                error: err?.response?.data
            }
        }
    },
};

export default SearchFindUser;