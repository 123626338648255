import React from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import Notfound from '../scenes/NotFound';
import Home from "../scenes/Home";
import Test from "../scenes/Test";
import Login from "../scenes/Login";
import GuestRoute from "./components/GuestRoute";
import PrivateRoute from "./components/PrivateRoute";
import SmuAssignOrder from "../scenes/SmuAssignOrder";
import SmuOrders from "../scenes/SmuOrders";
import Drivers from "../scenes/Drivers";
import Users from "../scenes/Users";
import FormExample from "../scenes/FormExample";
import SearchUser from "../scenes/SaveUser";
import FormBuk from "../scenes/FormBuk";
import FindUser from "../scenes/SearchUser";
import SearchFiles from "../scenes/SearchFiles";
import BlackList from "../scenes/BlackList";
import UpUser from "../scenes/UpUser";
import SavePlan from "../scenes/SavePlan";
import SaveJob from "../scenes/SaveJob";

export default function AppRoutes() {

    return (
        <Routes>
            <Route path="/" element={<Navigate to='/home' />} />
            <Route path="/home" element={<PrivateRoute outlet={<Home />} />} />
            <Route path="/smu-orders" element={<PrivateRoute outlet={<SmuOrders />} permissionsRequired={'SHOW_SMU_ORDER_HISTORY_MODULE'} />} />
            <Route path="/smu-assign-order" element={<PrivateRoute outlet={<SmuAssignOrder />} permissionsRequired={'SHOW_SMU_ASSIGN_ORDER_MODULE'} />} />
            <Route path="/users" element={<PrivateRoute outlet={<Users />} permissionsRequired={'SHOW_SMU_USERS_MAINTENANCE_MODULE'} />} />
            <Route path="/drivers" element={<PrivateRoute outlet={<Drivers />} permissionsRequired={'SHOW_SMU_DRIVER_MAINTENANCE_MODULE'} />} />
            <Route path="/form-example" element={<PrivateRoute outlet={<FormExample />} />} />
            <Route path="/create-user" element={<PrivateRoute outlet={<SearchUser />} />} />
            <Route path="/plan-user" element={<PrivateRoute outlet={<SavePlan />} />} />
            <Route path="/job-user" element={<PrivateRoute outlet={<SaveJob />} />} />
            <Route path="/up-user" element={<PrivateRoute outlet={<UpUser />} />} />
            <Route path="/search-user" element={<PrivateRoute outlet={<FindUser />} />} />
            <Route path="/search-files" element={<PrivateRoute outlet={<SearchFiles />} />} />
            <Route path="/black-list" element={<PrivateRoute outlet={<BlackList />} />} />
            <Route path="/login" element={<GuestRoute outlet={<Login />} />} />
            <Route path="/register" element={<GuestRoute outlet={<FormBuk />} />} />
            <Route path="/test" element={<Test />} />
            <Route path="*" element={<Notfound />} />
        </Routes>
    );
}
