import axios from "axios";
import {config} from "../../../config/config";
import User from "../../../models/User";
import {AvailableOrder} from "../models/AvailableOrder";

const api = axios.create({
    baseURL: config.API_BFF_TITASK_APP,
    headers: {
        "Accept": 'application/json',
        "Content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "lang": "es"
    },
})

const apiIntegrationsComunidad = axios.create({
    baseURL: config.API_INTEGRACIONES_COMUNIDADTASK_URI,
    headers: {
        "Accept": 'application/json',
        "Content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*"
    },
})

export const AssignOrderService = {
    getDrivers: async (token: string) => {
        try {
            const response = await api.post(`/app-user/get-smu-users`,
                { roleIds: ['DRIVER_SMU'] },
                { headers: { Authorization: `Bearer ${token}` },
            });

            const driversRawData = response.data.data;

            return {
                success: true,
                data: driversRawData.map((driverRawData: any): User => {
                    return {
                        id: driverRawData.id,
                        appRoleId: driverRawData.appRoleId,
                        email: driverRawData.email,
                        name: driverRawData.name,
                        lastname: driverRawData.lastname,
                        documentType: driverRawData.documentType,
                        documentNumber: driverRawData.documentNumber,
                        platform: driverRawData.platform,
                        plateNumber: driverRawData.plateNumber,
                        token: driverRawData.token,
                        carriers: !driverRawData.carriers ? [] : driverRawData.carriers.map((carrier: any) => carrier.id)
                    }
                })
            }
        } catch (err: any) {
            return {
                success: false,
                error: err
            }
        }
    },
    getAvailableOrders: async () => {
        try {
            const response = await apiIntegrationsComunidad.get(`/clients/smu/availables-orders`);
            return {
                success: true,
                data: response.data.orders.map((orderRaw: any): AvailableOrder => {
                    return {
                        orderClient: orderRaw.orderClient,
                        seqId: orderRaw.seqId,
                        store: orderRaw.store,
                        status: orderRaw.status.charAt(0).toUpperCase() + orderRaw.status.slice(1)
                    };
                })
            }
        }catch (err)
        {
            return {
                success: false,
                error: err
            }
        }
    },
    assignDriver: async (documentDriver: string, vehicle: string, seqIds: string[]) => {
        try {
            await apiIntegrationsComunidad.post(`/clients/smu/orders/assign`, { documentDriver, vehicle, orders: seqIds });
            return {
                success: true
            }
        }catch (err)
        {
            return {
                success: false,
                error: err
            }
        }
    }
}