import React from 'react';
import {ProForm, ProFormSelect, ProFormText} from "@ant-design/pro-components";
import {Alert, Modal} from "antd";
import {Carrier} from "../../../models/Carrier";

interface NewUserModalSchema {
    open: boolean;
    submit: (email: string, name: string, lastname: string, roleId: 'ADMIN_SMU' | 'CONTROL_TOWER_SMU', carriers: string[]) => void;
    setOpen: (value: boolean) => void;
    carriers: Carrier[];
}

const NewUserModal = ({open, submit, setOpen, carriers}: NewUserModalSchema): JSX.Element => {
    const getStores = () => {
        return (carriers || []).map((carrier) => {
            return {
                value: carrier.id,
                label: carrier.name
            }
        });
    }

    return (
        <Modal
            title="Crear nuevo usuario"
            open={open}
            destroyOnClose
            onCancel={() => { setOpen(false) }}
            closable
            keyboard
            footer={null}
        >
            <hr style={{ border: '0', borderTop: '1px solid #c3c3c3'}}/>

            <Alert
                description="La contraseña de acceso al sistema(app) será generada aleatoriamente y enviada al correo del nuevo usuario."
                type="info"
                showIcon
            />

            <ProForm<any>
                style={{ marginTop: '20px' }}
                onFinish={async (values: any) => {
                    await submit(values.email_input, values.name_input, values.lastname_input, values.role_id_input, values.carriers_input || []);
                }}
                submitter={{
                    searchConfig: {
                        submitText: 'Crear nuevo usuario'
                    },
                    resetButtonProps: {
                        style: {
                            display: 'none',
                        },
                    },
                    submitButtonProps: {
                        style: {
                            marginLeft: '-8px',
                        }
                    },
                }}
            >
                <ProForm.Group>
                    <ProFormText
                        name="email_input"
                        label="Email"
                        width="xl"
                        tooltip="El email no debe de estar actualmente en uso por nuestro sistema"
                        placeholder="Ingrese un email"
                        rules={[{ required: true, type: 'email', message: 'Se debe de ingresar un email con formato válido' }]}
                    />

                    <ProFormText
                        name="name_input"
                        label="Nombres"
                        width="xl"
                        placeholder="Ingrese los nombres"
                        rules={[{ required: true, message: 'Debe de ingresar los nombres' }, { min: 3, message: 'Los nombres deben de tener al menos 3 caracteres' }]}
                    />

                    <ProFormText
                        name="lastname_input"
                        label="Apellidos"
                        width="xl"
                        placeholder="Ingrese los apellidos"
                        rules={[{ required: true, message: 'Debe de ingresar los apellidos' }, { min: 3, message: 'Los apellidos deben de tener al menos 3 caracteres' }]}
                    />

                    <ProFormSelect
                        options={[
                            { value: 'ADMIN_SMU', label: 'Administrador' },
                            { value: 'CONTROL_TOWER_SMU', label: 'Torre de control' }
                        ]}
                        width="xl"
                        name="role_id_input"
                        label="Rol"
                        placeholder="Seleccione un rol"
                        rules={[{ required: true, message: 'Debe de seleccionar un rol' }]}
                    />

                    <ProFormSelect
                        options={getStores()}
                        width="xl"
                        name="carriers_input"
                        label="Tienda a cargo"
                        placeholder="-TODAS-"
                        mode="multiple"
                    />
                </ProForm.Group>
            </ProForm>
        </Modal>
    );
};

export default NewUserModal;