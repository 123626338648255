import React, { useEffect, useState } from "react";
import moment from "moment";
import { ProCard } from "@ant-design/pro-components";
import AuthAction from "../../redux/actions/authActions/actions";
import UserService from "./services/UserService";
import AppLayout from "../../components/Layouts/App/App";
import { FormLayout } from "antd/es/form/Form";
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Spin,
  DatePicker,
  Select,
} from "antd";
import type { SelectProps } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Radio } from "antd";
import "./registerU.css";
import { Col, Row, Space } from "antd";
import SearchFindUser from "../SearchUser/services/SearchService";
const LAYOUT_TYPE_HORIZONTAL = "horizontal";

const waitTime = (time: number = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

//Documentación: https://procomponents.ant.design/en-US/components/form

const SaveJob = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [comunas, setComunas] = useState([]);

  const [tiposCompania, setTiposCompania] = useState([]);
  const [tiposAreas, setTiposAreas] = useState([]);
  const [tiposAreasInit, setTiposAreasInit] = useState([]);
  const [tiposRoles, setTiposRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [formJob] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [idUser, setIdUser] = useState(0);
  const options: SelectProps["options"] = [
    { label: "Lunes", value: "l" },
    { label: "Martes", value: "m" },
    { label: "Miercoles", value: "w" },
    { label: "Jueves", value: "j" },
    { label: "Viernes", value: "v" },
    { label: "Sabado", value: "s" },
    { label: "Domingo", value: "d" },
  ];
  const onFinish = async (values: any) => {
    if (!loading) {
      const loadingMessageKey = "loading-message";
      messageApi.open({
        key: loadingMessageKey,
        type: "loading",
        content: "Search user...",
        duration: 0,
      });
      setLoading(true);

      const searchResponse = await SearchFindUser.findUser(values.rut);

      setLoading(false);

      messageApi.destroy(loadingMessageKey);

      if (searchResponse.success) {
        console.log(searchResponse.data![0]);
        if (searchResponse.data![0]) {
          const values: any = searchResponse.data![0];
          setIdUser(values.id);
          form.setFieldValue(
            "nombres",
            `${values.first_name} ${values.second_surname}`
          );
        }
      } else {
        messageApi.error(
          searchResponse.error?.data?.message ||
            "Hubo un error al crear al usuario, por favor inténtalo nuevamente.",
          3.5
        );
      }
    }
  };

  const onFinishJob = async (values: any) => {
    if (!loading) {
      const sendJob = {
        company_id: values.cmbCompanies,
        start_date: values.fecInicio
          ? moment(values.fecInicio?.["$d"]).format("YYYY-MM-DD")
          : "",
        type_of_contract: values.cmbTipoContrato,
        end_of_contract: values.fecTermino
          ? moment(values.fecTermino?.["$d"]).format("YYYY-MM-DD")
          : "",
        end_of_contract_2: values.fecTermino
          ? moment(values.fecTermino?.["$d"]).format("YYYY-MM-DD")
          : "",
        periodicity: values.cmbJornada,
        regular_hours: values.horasJornada ?? 0,
        "type_of working_day": values.cmbTipoJornada,
        location_id: values.cmbComuna,
        role_id: values.cmbRoles,
        area_id: values.cmbAreas,
        leader_id: values.leaderId,
        wage: parseInt(values.sueldo),
        currency: "peso",
        without_wage: false,
        days: values.dias_semana,
        custom_attributes:{
          "Comuna PDV": values.comunaPDV,
          "Dirección PDV": values.direccionPDV,
          "Nombre PDV": values.nombrePDV
        }
      };

      const loadingMessageKey = "loading-message";
      messageApi.open({
        key: loadingMessageKey,
        type: "loading",
        content: "Registrado user...",
        duration: 0,
      });
      setLoading(true);

      console.log(sendJob);
      const loginResponse = await UserService.saveUser({
        job: sendJob,
        tipo: 3,
        idUser: idUser,
      });
      setLoading(false);

      messageApi.destroy(loadingMessageKey);

      if (loginResponse.success) {
        messageApi.success("Se registro con ex.", 3.5);
        dispatch(AuthAction.signIn(loginResponse.data));
        // navigate('/');
      } else {
        messageApi.error(
          loginResponse.error?.data?.message ||
            "Hubo un error al crear al usuario, por favor inténtalo nuevamente.",
          3.5
        );
      }
    }
  };

  const cleanForm = () => {
    form.resetFields();
  };

  const cleanFormJob = () => {
    formJob.resetFields();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    messageApi.error("Por favor corrija los errores mencionados", 3.5);
  };

  const handleChangeDias = (value: string[]) => {
    console.log(`selected ${value}`);
  };
  const changeRol = (value: any) => {
    setLoading(true);
    setTiposAreas(tiposAreasInit);
    const rol: any = tiposRoles?.find((x: any) => x.value === value);
    const newAreas: any = [];
    tiposAreas.forEach((x: any) => {
      if (rol?.area_ids.includes(x.value)) {
        newAreas.push(x);
      }
    });
    setTiposAreas(newAreas);
    setLoading(false);
  };

  const loadCmbs = async () => {
    const comunas = await UserService.cmbLoad("comunas");
    if (comunas?.data.length > 0) setComunas(comunas?.data);
    const tiposCompania = await UserService.cmbLoadv2("companies");
    if (tiposCompania?.data.length > 0) setTiposCompania(tiposCompania?.data);
    const tiposRoles = await UserService.cmbLoadv2("roles");
    if (tiposRoles?.data.length > 0) setTiposRoles(tiposRoles?.data);

    const tiposAreas = await UserService.cmbLoadv2("areas");
    if (tiposAreas?.data.length > 0) {
      setTiposAreas(tiposAreas?.data);
      setTiposAreasInit(tiposAreas?.data);
    }
  };
  useEffect(() => {
    loadCmbs();
  }, []);

  return (
    <>
      {contextHolder}
      <AppLayout title="Registrar Trabajo Usuario">
        <ProCard
          style={{
            minHeight: 350,
            paddingLeft: "15%",
          }}
        >
          <Form
            form={form}
            name="login-form-rg"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="rut"
              rules={[{ required: true, message: "El Rut es requerido" }]}
            >
              <Input className="login-form-rg-text" placeholder="Rut" />
            </Form.Item>
            <Form.Item className="buttons-style">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button-rg"
              >
                BUSCAR
              </Button>
            </Form.Item>

            <Form.Item className="buttons-style">
              <Button
                type="primary"
                onClick={cleanForm}
                className="login-form-button-rg"
              >
                Limpiar
              </Button>
            </Form.Item>
            <Form.Item name="nombres">
              <Input
                disabled={true}
                className="login-form-rg-text"
                placeholder="Nombres"
              />
            </Form.Item>
          </Form>

          <Form
            form={formJob}
            name="login-form-rg"
            initialValues={{ remember: true }}
            onFinish={onFinishJob}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="fecInicio"
              rules={[
                { required: true, message: "La fecha de inicio es requerido" },
              ]}
            >
              <DatePicker placeholder="Fecha de Inicio" />
            </Form.Item>

            <Form.Item
              name="fecTermino"
              rules={[
                { required: true, message: "La fecha de termino es requerido" },
              ]}
            >
              <DatePicker placeholder="Fecha de Termino" />
            </Form.Item>

            <Form.Item
              name="cmbComuna"
              rules={[{ required: true, message: "Ciudad" }]}
            >
              <Select
                showSearch
                placeholder="Seleccione Comuna"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={comunas}
              />
            </Form.Item>

            <Form.Item
              name="cmbJornada"
              rules={[{ required: true, message: "Jornada" }]}
            >
              <Select
                placeholder="Seleccione Jornada"
                options={[
                  { value: "hora", label: "Horas" },
                  { value: "diaria", label: "Diaria" },
                  { value: "mensual", label: "Mensual" },
                ]}
              />
            </Form.Item>

            <Form.Item
              name="horasJornada"
              rules={[
                {
                  required: true,
                  message: "El numero de horas x jornada es requerido",
                },
              ]}
            >
              <Input
                className="login-form-rg-text"
                placeholder="Horas x Jornada"
              />
            </Form.Item>
            <Form.Item
              name="dias_semana"
              rules={[
                {
                  required: true,
                  message: "El numero de dias x semana es requerido",
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                onChange={handleChangeDias}
                options={options}
              />
            </Form.Item>

            <Form.Item
              name="sueldo"
              rules={[{ required: true, message: "El sueldo es requerido" }]}
            >
              <Input className="login-form-rg-text" placeholder="Sueldo" />
            </Form.Item>

            <Form.Item
              name="cmbTipoContrato"
              rules={[{ required: true, message: "Contrato" }]}
            >
              <Select
                placeholder="Seleccione Tipo Contrato"
                options={[
                  { value: "Indefinido", label: "Indefinido" },
                  { value: "Obra", label: "Obra" },
                  { value: "plazo_fijo", label: "Plazo Fijo" },
                  {
                    value: "renovacion_automática",
                    label: "Renovacion Automatica",
                  },
                ]}
              />
            </Form.Item>

            <Form.Item
              name="cmbTipoJornada"
              rules={[{ required: true, message: "Jornada" }]}
            >
              <Select
                placeholder="Seleccione Tipo Jornada"
                options={[
                  { value: "ordinaria_art_22", label: "ordinaria_art_22" },
                  { value: "parcial_art_40_bis", label: "parcial_art_40_bis" },
                  { value: "exenta_art_22", label: "exenta_art_22" },
                  { value: "otros", label: "otros" },
                ]}
              />
            </Form.Item>
            <Form.Item name="cmbOtrasTipoJornada">
              <Select
                showSearch
                placeholder="Seleccione Otras Tipo Jornada"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "extraordinaria_art_30",
                    label: "extraordinaria_art_30",
                  },
                  {
                    value: "especial_art_38_inc_5",
                    label: "especial_art_38_inc_5",
                  },
                  { value: "especial_art_23", label: "especial_art_23" },
                  { value: "especial_art_106", label: "especial_art_106" },
                  {
                    value: "especial_art_152_ter_d",
                    label: "especial_art_152_ter_d",
                  },
                  {
                    value: "especial_art_152_ter_f",
                    label: "especial_art_152_ter_f",
                  },
                  { value: "especial_art_25", label: "especial_art_25" },
                  {
                    value: "especial_art_25_bis",
                    label: "especial_art_25_bis",
                  },
                  { value: "especial_art_149", label: "especial_art_149" },
                  {
                    value: "especial_art_149_inc_2",
                    label: "especial_art_149_inc_2",
                  },
                  {
                    value: "especial_art_152_bis",
                    label: "especial_art_152_bis",
                  },
                  {
                    value: "especial_art_36_145_d",
                    label: "especial_art_36_145_d",
                  },
                  {
                    value: "especial_art_22_inc_final",
                    label: "especial_art_22_inc_final",
                  },
                  {
                    value: "bisemanal_art_149_inc_2",
                    label: "bisemanal_art_149_inc_2",
                  },
                  {
                    value: "excepcional_art_38_inc_final",
                    label: "excepcional_art_38_inc_final",
                  },
                ]}
              />
            </Form.Item>

            <Form.Item
              name="cmbCompanies"
              rules={[{ required: true, message: "Compañia" }]}
            >
              <Select
                showSearch
                placeholder="Seleccione Compañias"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={tiposCompania}
              />
            </Form.Item>
            <Form.Item
              name="cmbRoles"
              rules={[{ required: true, message: "Roles" }]}
            >
              <Select
                showSearch
                placeholder="Seleccione Rol"
                optionFilterProp="children"
                onChange={changeRol}
                filterOption={(input, option: any) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={tiposRoles}
              />
            </Form.Item>
            <Form.Item
              name="cmbAreas"
              rules={[{ required: true, message: "Areas" }]}
            >
              <Select
                showSearch
                placeholder="Seleccione Area"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={tiposAreas}
              />
            </Form.Item>

            <Form.Item
              name="leaderId"
              rules={[{ required: true, message: "El Id Lider requerido" }]}
            >
              <Select
                placeholder="Seleccione Lider "
                options={[
                  { value: "12224", label: "Natassja Belen Aguilar Rojas" },
                ]}
              />
            </Form.Item>

            <Form.Item
                            name="comunaPDV"
                            rules={[
                                { required: true, message: 'La comuna pdv es requerido' },
                            ]}
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Comuna PDV"
                            />
                        </Form.Item>
                        <Form.Item
                            name="direccionPDV"
                            rules={[
                                { required: true, message: 'La direccion pdv es requerido' },
                            ]}
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Direccion PDV"
                            />
                        </Form.Item>

                        <Form.Item
                            name="nombrePDV"
                            rules={[
                                { required: true, message: 'La nombre pdv es requerido' },
                            ]}
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Nombre PDV"
                            />
                        </Form.Item>
            <Form.Item className="buttons-style">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button-rg"
              >
                REGISTAR
              </Button>
            </Form.Item>
            <Form.Item className="buttons-style">
              <Button
                type="primary"
                onClick={cleanFormJob}
                className="login-form-button-rg"
              >
                Limpiar
              </Button>
            </Form.Item>
          </Form>
        </ProCard>
      </AppLayout>
    </>
  );
};

export default SaveJob;
