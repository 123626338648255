import React from 'react';
import {Carrier} from "../../../models/Carrier";
import User from "../../../models/User";
import {ProForm, ProFormSelect, ProFormText} from "@ant-design/pro-components";
import {Modal} from "antd";

interface EditUserModalSchema {
    user: User;
    open: boolean;
    submit: (id: number, name: string, lastname: string, roleId: string, carriers: string[]) => void;
    setOpen: (value: boolean) => void;
    carriers: Carrier[];
    disabled: boolean;
}

const EditUserModal = ({user, open, submit, setOpen, disabled, carriers}: EditUserModalSchema): JSX.Element => {
    const getStores = () => {
        return (carriers || []).map((carrier) => {
            return {
                value: carrier.id,
                label: carrier.name
            }
        });
    }

    return (
        <Modal
            title={`${disabled ? 'Detalles': 'Editar'} usuario (ID: ${user.id})`}
            open={open}
            destroyOnClose
            onCancel={() => { setOpen(false) }}
            closable
            keyboard
            footer={null}
        >
            <hr style={{ border: '0', borderTop: '1px solid #c3c3c3'}}/>

            <ProForm<any>
                style={{ marginTop: '20px' }}
                onFinish={async (values: any) => {
                    if(!disabled) {

                        await submit(user.id, values.name_input, values.lastname_input, values.role_id_input, values.carriers_input || []);
                    }
                }}
                submitter={{
                    searchConfig: {
                        submitText: 'Guardar cambios'
                    },
                    resetButtonProps: {
                        style: {
                            display: 'none',
                        },
                    },
                    submitButtonProps: {
                        style: {
                            marginLeft: '-8px',
                            display: disabled ? 'none' : 'block',
                        },
                    },
                }}
            >
                <ProForm.Group>
                    <ProFormText
                        name="email_input"
                        label="Email"
                        width="xl"
                        tooltip="El email no debe de estar actualmente en uso por nuestro sistema"
                        placeholder="Ingrese un email"
                        disabled={true}
                        initialValue={user.email}
                    />

                    <ProFormText
                        name="name_input"
                        label="Nombres"
                        width="xl"
                        placeholder="Ingrese los nombres"
                        rules={[{ required: true, message: 'Debe de ingresar los nombres' }, { min: 3, message: 'Los nombres deben de tener al menos 3 caracteres' }]}
                        initialValue={user.name}
                        disabled={disabled}
                    />

                    <ProFormText
                        name="lastname_input"
                        label="Apellidos"
                        width="xl"
                        placeholder="Ingrese los apellidos"
                        rules={[{ required: true, message: 'Debe de ingresar los apellidos' }, { min: 3, message: 'Los apellidos deben de tener al menos 3 caracteres' }]}
                        initialValue={user.lastname}
                        disabled={disabled}
                    />

                    <ProFormSelect
                        options={[
                            { value: 'ADMIN_SMU', label: 'Administrador' },
                            { value: 'CONTROL_TOWER_SMU', label: 'Torre de control' }
                        ]}
                        width="xl"
                        name="role_id_input"
                        label="Rol"
                        placeholder="Seleccione un rol"
                        rules={[{ required: true, message: 'Debe de seleccionar un rol' }]}
                        disabled={disabled}
                        initialValue={user.appRoleId}
                    />

                    <ProFormSelect
                        options={getStores()}
                        width="xl"
                        name="carriers_input"
                        label="Tienda a cargo"
                        placeholder="-TODAS-"
                        disabled={disabled}
                        initialValue={user.carriers}
                        mode="multiple"
                    />
                </ProForm.Group>
            </ProForm>
        </Modal>
    );
};

export default EditUserModal;