import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { ProCard } from "@ant-design/pro-components";
import AuthAction from "../../redux/actions/authActions/actions";
import SearchFilesService from "./services/SearchService";
import AppLayout from "../../components/Layouts/App/App";
import { FormLayout } from "antd/es/form/Form";
import { Button, Checkbox, Form, Input, message, Spin, DatePicker, Select, List, Avatar, UploadProps, UploadFile } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import './registerU.css';
import { Col, Row, Space } from 'antd';
import Icon from '@ant-design/icons/lib/components/Icon';
import { CloudDownloadOutlined, InboxOutlined, SearchOutlined, DeleteFilled } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import { RcFile } from 'antd/es/upload';
const LAYOUT_TYPE_HORIZONTAL = 'horizontal';



const waitTime = (time: number = 100) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};


//Documentación: https://procomponents.ant.design/en-US/components/form

const BlackList = (): JSX.Element => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [datalist, setDatalist] = useState([] as any);
    const [userId, serUserId] = useState('' as any);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState(false);
    const { email } = useSelector((state: any) => state.auth);
    const onFinish = async (values: any) => {
        if (!loading) {

            const loadingMessageKey = 'loading-message';
            messageApi.open({
                key: loadingMessageKey,
                type: 'loading',
                content: 'Guardando Usuario...',
                duration: 0
            });
            setLoading(true);

            const searchResponse: any = await SearchFilesService.saveBlackList({ rut: values.rut, email });
            setLoading(false);

            messageApi.destroy(loadingMessageKey);

            if (searchResponse.success) {
                await initMode();
            } else {
                messageApi.error(searchResponse.error?.data?.message || 'Hubo un error al agregar el rut del usuario, por favor inténtalo nuevamente.', 3.5);
            }
        }
    };

    const deleteFile = async (values: any) => {
        if (!loading) {

            const loadingMessageKey = 'loading-message';
            messageApi.open({
                key: loadingMessageKey,
                type: 'loading',
                content: 'Elimaninando ...',
                duration: 0
            });
            setLoading(true);
            const searchResponse: any = await SearchFilesService.deleteBlackList({ id: values._id, email });
            await initMode();
            setLoading(false);

            messageApi.destroy(loadingMessageKey);

            if (searchResponse.success) {
                messageApi.success('Se elimino con exito el rut', 3.5);

            } else {
                messageApi.error(searchResponse.error?.data?.message || 'Hubo un error al eliminar el rut', 3.5);
            }
        }
    };
    const initMode = async () => {
        if (!loading) {

            const loadingMessageKey = 'loading-message';
            messageApi.open({
                key: loadingMessageKey,
                type: 'loading',
                content: 'Loading ...',
                duration: 0
            });
            setLoading(true);
            const searchResponse: any = await SearchFilesService.findBlackList({});

            setLoading(false);

            messageApi.destroy(loadingMessageKey);

            if (searchResponse.success) {
                setDatalist(searchResponse.data)
            } else {
                messageApi.error(searchResponse.error?.data?.message || 'Hubo un error al cargar la black list', 3.5);
            }
        }
    };
    useEffect(() => {
        initMode();
    }, []);
    const handleUpload = async () => {
        const loadingMessageKey = 'loading-message-valor';
        setUploading(true);
        setLoading(true);
        messageApi.open({
            key: loadingMessageKey,
            type: 'loading',
            content: 'Upload Files...',
            duration: 0
        });
        for (const file of fileList) {
            const formData = new FormData();
            formData.append('file', file as RcFile);
            const searchResponse: any = await SearchFilesService.uploadFile(userId, formData);
            console.log(searchResponse)
            if (searchResponse?.data?.employee_file) {
                const fileData = searchResponse?.data?.employee_file;
                datalist.unshift({ filename: fileData.original_filename, created_at: fileData.created_at, file_id: fileData.id })
                setDatalist(datalist)
            }
        }

        setLoading(false);
        setUploading(false);
        messageApi.destroy(loadingMessageKey);
    };

    const props: UploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };
    const cleanForm = () => {
        form.resetFields();
        setDatalist([])
        serUserId('')
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        messageApi.error('Por favor corrija los errores mencionados', 3.5);
    };
    return (
        <>
            {contextHolder}
            <AppLayout title="Lista de Usuarios Bloqueados">
                <ProCard
                    style={{
                        minHeight: 350,
                        paddingLeft: '15%'
                    }}
                >
                    <Form
                        form={form}
                        name="login-form-rg"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >

                        <Form.Item
                            name="rut"
                            rules={[
                                { required: true, message: 'El Rut es requerido' },
                            ]}
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Rut"
                            />
                        </Form.Item>
                        <Form.Item
                            className='buttons-style'
                        >
                            <Button type="primary" htmlType="submit" className="login-form-button-rg">
                                GUARDAR
                            </Button>
                        </Form.Item>
                        <Form.Item
                            className='buttons-style'>
                            <Button type="primary" onClick={cleanForm} className="login-form-button-rg">
                                Limpiar
                            </Button>
                        </Form.Item>
                        <List<any>
                            size="small"
                            style={{ marginTop: '25px' }}
                            header={<b style={{ color: 'rgb(135 130 130)' }}>Usuarios de Bloqueados</b>}
                            footer={false}
                            bordered
                            dataSource={datalist}
                            pagination={{
                                onChange: (page) => {
                                    console.log(page);
                                },
                                pageSize: 5,
                            }}
                            renderItem={(item: any) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar style={{ marginTop: '4px' }} src={'https://cdn-icons-png.flaticon.com/512/219/219986.png'} />}
                                        title={`Rut:${item.rut}`}
                                        description={`🕞 Creado: ${item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY HH:mm') : ''}  🗂️ Usuario:${item.email}`}
                                    />
                                    <Button onClick={() => deleteFile(item)} icon={<DeleteFilled />}>
                                    </Button>
                                </List.Item>
                            )}
                        />



                    </Form>
                </ProCard>
            </AppLayout >
        </>
    );
};

export default BlackList;