import {config} from "../../../config/config";
import User from "../../../models/User";
import axios from "axios";

const api = axios.create({
    baseURL: config.API_BFF_TITASK_APP,
    headers: {
        "Accept": 'application/json',
        "Content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "lang": "es"
    },
})

export const DriverService = {
    getDrivers: async (token: string) => {
        try {
            const response = await api.post(`/app-user/get-smu-users`,
                { roleIds: ['DRIVER_SMU'] },
                {  headers: { Authorization: `Bearer ${token}` },
            });

            const driversRawData = response.data.data;

            return {
                success: true,
                data: driversRawData.map((driverRawData: any): User => {
                    return {
                        id: driverRawData.id,
                        appRoleId: driverRawData.appRoleId,
                        email: driverRawData.email,
                        name: driverRawData.name,
                        lastname: driverRawData.lastname,
                        documentType: driverRawData.documentType,
                        documentNumber: driverRawData.documentNumber,
                        platform: driverRawData.platform,
                        plateNumber: driverRawData.plateNumber,
                        token: driverRawData.token,
                        carriers: !driverRawData.carriers ? [] : driverRawData.carriers.map((carrier: any) => carrier.id)
                    }
                })
            }
        } catch (err: any) {
            return {
                success: false,
                error: err
            }
        }
    },
    createDriver: async (token: string, email: string, name: string, lastname: string, documentType: string, documentNumber: string, plateNumber: string, carriers: string[]) => {
        try {
            const response = await api.post(`/app-user/create-smu-user`,
                { email, name, lastname, documentType, documentNumber, plateNumber, roleId: 'DRIVER_SMU', carriers },
                {  headers: { Authorization: `Bearer ${token}` },
                });

            return {
                success: true,
                data: response.data.data
            }
        } catch (err: any) {
            return {
                success: false,
                error: err
            }
        }
    },
    updateDriver: async (token: string, id: number, name: string, lastname: string, documentType: string, documentNumber: string, plateNumber: string, carriers: string[]) => {
        try {
            const response = await api.put(`/app-user/update-smu-user/${id}`,
                { name, lastname, documentType, documentNumber, plateNumber, roleId: 'DRIVER_SMU', carriers },
                {  headers: { Authorization: `Bearer ${token}` },
                });

            return {
                success: true,
                data: response.data.data
            }
        } catch (err: any) {
            return {
                success: false,
                error: err
            }
        }
    },
    checkEmailExistence: async (token: string, email: string) => {
        try {
            const response = await api.put(`/app-user/check-email-existence`,
                { email },
                {  headers: { Authorization: `Bearer ${token}` },
                });

            return {
                success: true,
                data: response.data.data
            }
        } catch (err: any) {
            return {
                success: false,
                error: err
            }
        }
    },
}