import {config} from "../../../config/config";
import {Order} from "../models/Order";
import {History} from "../models/History";
import {OrderResponse} from "../models/OrderResponse";
import axios from "axios";
import {OrderItem} from "../models/OrderItem";

const apiIntegrationsComunidad = axios.create({
    baseURL: config.API_INTEGRACIONES_COMUNIDADTASK_URI,
    headers: {
        "Accept": 'application/json',
        "Content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*"
    },
})

export const OrderService = {
    getOrders: async (page: number, size: number = 30, orderClientFilterValue?: string, storeId?: string[]) => {
        try {
            const response = await apiIntegrationsComunidad.post(`/clients/smu/orders/${page}/${size}`, {
                findValue: orderClientFilterValue,
                storeId: storeId || []
            });
            return {
                success: true,
                data: {
                    totalRows: response.data.count,
                    orders: response.data.orders.map((row: any): Order => {
                        return {
                            orderClient: row.orderClient,
                            store: row.store,
                            vehicle: row.vehicle,
                            documentDriver: row.documentDriver,
                            seqId: row.seqId,
                            userName: row.user.name,
                            userEmail: row.user.email,
                            userPhoneNumber: row.user.phoneNumber,
                            userIdentificationNumber: (row.user.identificationNumber.split('-').length > 0 ?  row.user.identificationNumber.split('-')[1] : row.user.identificationNumber),
                            address: row.address,
                            city: row.city,
                            latitude: row.latitude,
                            longitude: row.longitude,
                            status: row.status.charAt(0).toUpperCase() + row.status.slice(1),
                            history: row.history.map((historyRaw: any): History => {
                                return {
                                    status: historyRaw.status,
                                    date: new Date(historyRaw.date),
                                    image1: historyRaw.image1,
                                    image2: historyRaw.image2,
                                    image3: historyRaw.image3,
                                };
                            }),
                            items: row.items.map((itemRaw: any): OrderItem => {
                                return {
                                    id: itemRaw.id,
                                    name: itemRaw.name,
                                    comment: itemRaw.comment,
                                    photoUrl: itemRaw.photo_url,
                                    price: itemRaw.price,
                                    quantity: itemRaw.quantity,
                                    unit: itemRaw.unit,
                                    category: itemRaw.attributes?.category || ''
                                };
                            })
                        }
                    })
                } as OrderResponse
            }
        }catch (err)
        {
            return {
                success: false,
                error: err
            }
        }
    },
}