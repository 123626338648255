import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { ProCard } from "@ant-design/pro-components";
import AuthAction from "../../redux/actions/authActions/actions";
import UserService from "./services/UserService";
import AppLayout from "../../components/Layouts/App/App";
import { Button, Checkbox, Form, Input, message, Spin, DatePicker, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import './registerU.css';
const LAYOUT_TYPE_HORIZONTAL = 'horizontal';



const waitTime = (time: number = 100) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};


//Documentación: https://procomponents.ant.design/en-US/components/form

const SearchUser = (): JSX.Element => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [nacionalidades, setNacionalidades] = useState([])
    const [ciudades, setCiudades] = useState([])
    const [comunas, setComunas] = useState([])
    const [estadoCivil, setEstadoCivil] = useState([])
    const [bancos, setBancos] = useState([])
    const [tiposCuenta, setTiposCuenta] = useState([])
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const onFinish = async (values: any) => {
        if (!loading) {
            const sendBody = {
                first_name: values.nombres,
                surname: values.apellidos,
                second_surname: values.nombres,
                rut: values.rut,
                nationality: values.cmbNacionalidad,
                gender: values.cmbGenero,
                civil_status: values.cmbEstadoCivil,
                phone:values.telefono,
                birthday: values.fechnac ? moment(values.fechnac?.['$d']).format('YYYY-MM-DD') : '',
                email: values.email,
                personal_email: values.email,
                location_id: values.cmbComuna,
                address: values.direccion,
                city: values.cmbCiudad,
                payment_method: values.cmbPago,
                bank: values.cmbBanco,
                account_type: values.cmbCuenta,
                account_number: values.nroCuenta,
                street:values.calle,
                street_number: values.NroCalle
            
            }
          
            const loadingMessageKey = 'loading-message';
            messageApi.open({
                key: loadingMessageKey,
                type: 'loading',
                content: 'Registrado user...',
                duration: 0
            });
            setLoading(true);
       
        
            const loginResponse = await UserService.saveUser({user:sendBody,tipo:1});

            setLoading(false);

            messageApi.destroy(loadingMessageKey);

            if (loginResponse.success) {
                messageApi.success('Se registro con exito.', 3.5);
                dispatch(AuthAction.signIn(loginResponse.data));
                // navigate('/');
            } else {
                messageApi.error(loginResponse.error?.data?.message || 'Hubo un error al crear al usuario, por favor inténtalo nuevamente.', 3.5);
            }
        }
    };
    const cleanForm = () => {
        form.resetFields();
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        messageApi.error('Por favor corrija los errores mencionados', 3.5);
    };
    const loadCmbs = async () => {

        
        const nacionalidades = await UserService.cmbLoad('nacionalidades');
        if (nacionalidades?.data.length > 0) setNacionalidades(nacionalidades?.data)
        const ciudades = await UserService.cmbLoad('ciudades');
        if (ciudades?.data.length > 0){
            const ciudadesadd = ciudades?.data?.map((x:any)=> {return {label:x.label,value:x.label}})
            setCiudades(ciudadesadd)
        } 
        const comunas = await UserService.cmbLoad('comunas');
        if (comunas?.data.length > 0) setComunas(comunas?.data)
        const estadoCivil = await UserService.cmbLoad('estadoCivil');
        if (estadoCivil?.data.length > 0) setEstadoCivil(estadoCivil?.data)
        const bancos = await UserService.cmbLoad('bancos');
        if (bancos?.data.length > 0) setBancos(bancos?.data)
        const tiposCuenta = await UserService.cmbLoad('tiposCuenta');
        if (tiposCuenta?.data.length > 0) setTiposCuenta(tiposCuenta?.data)
    
      

        
        
    }
    useEffect(() => {
        loadCmbs()
    }, []);


    return (
        <>
            {contextHolder}
            <AppLayout title="Registrar Usuario">
                <ProCard
                    style={{
                        minHeight: 350,
                        paddingLeft: '15%'
                    }}
                >
                    <Form
                        form={form}
                        name="login-form-rg"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >

                        <Form.Item
                            name="rut"
                            rules={[
                                { required: true, message: 'El Rut es requerido' },
                            ]}
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Rut"
                            />
                        </Form.Item>



                        <Form.Item
                            name="nombres"
                            rules={[
                                { required: true, message: 'El nombre es requerido' },
                            ]}
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Nombres"
                            />
                        </Form.Item>

                        <Form.Item
                            name="apellidos"
                            rules={[
                                { required: true, message: 'La apellido es requerido' },
                            ]}
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Apellidos"
                            />
                        </Form.Item>

                        <Form.Item
                            name='cmbNacionalidad'
                            rules={[
                                { required: true, message: 'La nacionalidad es requerido' },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Seleccione Nacionalidad"
                                optionFilterProp="children"
                                filterOption={(input, option:any) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={nacionalidades}
                            />
                        </Form.Item>



                        <Form.Item
                            name="fechnac"
                            rules={[
                                { required: true, message: 'La fecha nacio es requerido' },
                            ]}
                        >
                            <DatePicker
                                placeholder="Fecha de nacimiento"
                            />
                        </Form.Item>

                        <Form.Item
                            name="direccion"
                            rules={[
                                { required: true, message: 'La direccion es requerido' },
                            ]}
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Direccion"

                            />
                        </Form.Item>

                        <Form.Item
                            name="calle"
                            rules={[
                                { required: true, message: 'La calle es requerido' },
                            ]}
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Calle"

                            />
                        </Form.Item>

                        <Form.Item
                            name="NroCalle"
                            rules={[
                                { required: true, message: 'El Numero de Calle es requerido' },
                            ]}
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="NroCalle"

                            />
                        </Form.Item>



                        <Form.Item
                            name='cmbCiudad'
                            rules={[
                                { required: true, message: 'Ciudad' },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Seleccione Ciudad"
                                optionFilterProp="children"
                                filterOption={(input, option:any) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={ciudades}
                            />
                        </Form.Item>

                        <Form.Item
                            name='cmbComuna'
                            rules={[
                                { required: true, message: 'Ciudad' },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Seleccione Comuna"
                                optionFilterProp="children"
                                filterOption={(input, option:any) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={comunas}
                            />
                        </Form.Item>



                        <Form.Item
                            name="telefono"
                            rules={[
                                { required: true, message: 'El telefono es requerido' },
                            ]}
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Telefono"
                            />
                        </Form.Item>

                        <Form.Item
                            name='cmbGenero'
                            rules={[
                                { required: true, message: 'Genero' },
                            ]}>
                            <Select
                                placeholder="Seleccione Genero"
                                options={[
                                    { value: 'M', label: 'Masculino' },
                                    { value: 'F', label: 'Femenino' }
                                ]}
                            />
                        </Form.Item>


                        <Form.Item
                            name='cmbEstadoCivil'
                            rules={[
                                { required: true, message: 'Estado Civil' },
                            ]}>
                            <Select
                                placeholder="Seleccione Estado Civil"
                                options={estadoCivil}
                            />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            rules={[
                                { required: true, message: 'El correo electrónico es requerido' },
                                { type: 'email', message: 'Debe de ingresar un correo electrónico válido' },

                            ]}
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Email"
                            />
                        </Form.Item>


                        <Form.Item
                            name='cmbBanco'
                            rules={[
                                { required: true, message: 'Banco' },
                            ]}>
                            <Select
                              showSearch
                              placeholder="Seleccione Banco"
                              optionFilterProp="children"
                              filterOption={(input, option:any) => (option?.label ?? '').includes(input)}
                              filterSort={(optionA, optionB) =>
                              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                              }
                                options={bancos}
                            />
                        </Form.Item>

                        <Form.Item
                            name='cmbCuenta'
                            rules={[
                                { required: true, message: 'Cuenta' },
                            ]}>
                            <Select
                                placeholder="Seleccione Tipo Cuenta"
                                options={tiposCuenta}
                            />
                        </Form.Item>

                        <Form.Item
                            name="nroCuenta"
                            rules={[
                                { required: true, message: 'El numero de cuenta es requerido' },
                            ]}
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Nro.Cuenta"
                            />
                        </Form.Item>


                        <Form.Item
                            name='cmbPago'
                            rules={[
                                { required: true, message: 'Pago' },
                            ]}>
                            <Select
                                placeholder="Seleccione Metodo Pago"
                                options={[
                                    { value: 'Transferencia Bancaria', label: 'Transferencia Bancaria' },
                                ]}
                            />
                        </Form.Item>

                       
                        <Form.Item
                            className='buttons-style'
                        >
                            <Button type="primary" htmlType="submit" className="login-form-button-rg">
                                REGISTAR
                            </Button>
                        </Form.Item>
                        <Form.Item
                            className='buttons-style'>
                            <Button type="primary" onClick={cleanForm} className="login-form-button-rg">
                                Limpiar
                            </Button>
                        </Form.Item>
                    </Form>
                </ProCard>
            </AppLayout >
        </>
    );
};

export default SearchUser;