import React, {useEffect, useRef, useState} from 'react';
import AppLayout from "../../components/Layouts/App/App";
import {ActionType, ProCard, ProColumns, ProTable} from "@ant-design/pro-components";
import User from "../../models/User";
import {DriverService} from "./services/DriverService";
import {Button, message} from "antd";
import {useSelector} from "react-redux";
import {ContextMenuSchema} from "../SmuOrders/components/ContextMenu";
import DriverContextMenu from "./components/DriverContextMenu";
import {PlusOutlined} from "@ant-design/icons";
import NewDriverModal from "./components/NewDriverModal";
import EditDriverModal from "./components/EditDriverModal";
import {UserService} from "../Users/services/UserService";
import {Carrier} from "../../models/Carrier";

const columns: ProColumns<User>[] = [
    {
        title: 'ID',
        dataIndex: 'id',
        width: 70,
        render: text => <b>{text}</b>
    },
    {
        title: 'EMAIL',
        dataIndex: 'email',
        ellipsis: true,
        width: 250
    },
    {
        title: 'NOMBRES',
        dataIndex: 'name',
        ellipsis: true,
        width: 250
    },
    {
        title: 'APELLIDOS',
        dataIndex: 'lastname',
        ellipsis: true,
        width: 250
    },
    {
        title: 'TIPO DE DOC.',
        dataIndex: 'documentType',
        width: 150
    },
    {
        title: 'NRO. DOCUMENTO',
        dataIndex: 'documentNumber',
        width: 150
    },
    {
        title: 'NRO. PLACA',
        dataIndex: 'plateNumber',
        width: 150
    },
];

const contextMenuInitialPosition: ContextMenuSchema = {
    record: undefined,
    visible: false,
    x: 0,
    y: 0
}

const Drivers = (): JSX.Element => {
    const [messageApi, messageContextHolder] = message.useMessage();
    const authUser = useSelector((state: any) => state.auth);
    const ref = useRef<ActionType>();
    const [contextMenuPosition, setContextMenuPosition] = useState<ContextMenuSchema>(contextMenuInitialPosition);
    const [openNewDriverModal, setOpenNewDriverModal] = useState(false);
    const [openEditDriverModal, setOpenEditDriverModal] = useState(false);
    const [driverSelected, setDriverSelected] = useState<User | null>(null);
    const [isDisabledEditDriverModal, setIsDisabledEditDriverModal] = useState(false);
    const [carriers, setCarriers] = useState<Carrier[]>([]);

    useEffect(() => {
        const init = async () => {
            const getCarriersResponse = await UserService.getCarriers();

            if (getCarriersResponse.success) {
                setCarriers(getCarriersResponse.data);
            } else {
                console.error(getCarriersResponse);
                messageApi.error(`No se pudieron cargar las tiendas, por favor inténtelo más tarde.`, 3.5);
            }
        }

        init();
    }, []);

    const newDriver = async (email: string, name: string, lastname: string, documentType: string, documentNumber: string, plateNumber: string, carriers: string[]) => {
        const newDriverResponse = await DriverService.createDriver(authUser.token, email, name, lastname, documentType, documentNumber, plateNumber, carriers);

        if(newDriverResponse.success) {
            messageApi.success(`Se registró el driver satisfactoriamente.`, 3.5);
            setOpenNewDriverModal(false);
            const table = ref.current as any;
            table.reload();
        }else{
            messageApi.error(newDriverResponse.error?.response?.data?.data?.message || 'Ups, ocurrió un error inesperado, por favor inténtelo nuevamente.', 3.5);
        }
    }

    const showEditDriverModal = async (driverSelected: User, isDisabled: boolean) => {
        setIsDisabledEditDriverModal(isDisabled);

        const loadingMessageKey = 'loading-message-edit-driver';

        messageApi.open({
            key: loadingMessageKey,
            type: 'loading',
            content: 'Obteniendo información...',
            duration: 0
        });

        const userResponse = await UserService.findUserById(authUser.token, driverSelected.id);

        messageApi.destroy(loadingMessageKey);

        if(userResponse.success) {
            setDriverSelected(userResponse.data as User);
            setOpenEditDriverModal(true);
        }else{
            messageApi.error(userResponse.error?.response?.data?.data?.message || 'Ups, ocurrió un error inesperado, por favor inténtelo nuevamente.', 3.5);
        }
    }

    const editDriver = async (id: number, name: string, lastname: string, documentType: string, documentNumber: string, plateNumber: string, carriers: string[]) => {
        const editDriverResponse = await DriverService.updateDriver(authUser.token, id, name, lastname, documentType, documentNumber, plateNumber, carriers);

        if(editDriverResponse.success) {
            messageApi.success(`Se editó el driver satisfactoriamente.`, 3.5);
            setOpenEditDriverModal(false);
            const table = ref.current as any;
            table.reload();
        }else{
            messageApi.error(editDriverResponse.error?.response?.data?.data?.message || 'Ups, ocurrió un error inesperado, por favor inténtelo nuevamente.', 3.5);
        }
    }

    return (
        <>
            <DriverContextMenu contextMenuPosition={contextMenuPosition} editDriver={showEditDriverModal} />

            <AppLayout title="Mantenimiento de drivers">
                {messageContextHolder}

                <NewDriverModal open={openNewDriverModal} submit={newDriver} setOpen={setOpenNewDriverModal} carriers={carriers} />
                {
                    driverSelected && (<EditDriverModal driver={driverSelected} open={openEditDriverModal} submit={editDriver} setOpen={setOpenEditDriverModal} disabled={isDisabledEditDriverModal} carriers={carriers}/>)
                }

                <ProCard
                    style={{
                        minHeight: 350,
                    }}
                >
                    <ProTable<User>
                        scroll={{ x: 600 }}
                        actionRef={ref}
                        size="small"
                        columns={columns}
                        cardBordered
                        request={async (params = {}, sort, filter) => {
                            const keyWord = (params.keyword || '').trim().toUpperCase();

                            const driversResponse = await DriverService.getDrivers(authUser.token);

                            if(driversResponse.success) {

                                let drivers = driversResponse.data.sort((a: User, b: User) => {
                                    return b.id - a.id;
                                });

                                if(keyWord) {
                                    drivers = drivers.filter((driver: User) => {
                                        return driver.email.toUpperCase().indexOf(keyWord) !== -1 || driver.name.toUpperCase().indexOf(keyWord) !== -1
                                            || driver.lastname.toUpperCase().indexOf(keyWord) !== -1 || (driver.plateNumber || '').toUpperCase().indexOf(keyWord) !== -1
                                            || (driver.documentNumber || '').toUpperCase().indexOf(keyWord) !== -1;
                                    });
                                }

                                return {
                                    success: true,
                                    data: drivers,
                                    total: drivers.length
                                };
                            }else{
                                messageApi.error(driversResponse.error?.response?.data?.data?.message || 'Ups, ocurrió un error inesperado, por favor inténtelo nuevamente.', 3.5);
                            }

                            return {
                                success: false,
                                data: [],
                                total: 0
                            };
                        }}
                        editable={{
                            type: 'multiple',
                        }}
                        rowKey="id"
                        search={false}
                        options={{
                            fullScreen: true,
                            reload: true,
                            setting: false,
                            density: false,
                            search: true
                        }}
                        pagination={{
                            pageSize: 12,
                            onChange: (page) => console.log(page),
                        }}
                        dateFormatter="string"
                        onRow={(record, rowIndex) => {
                            return {
                                onContextMenu: (event) => {
                                    event.preventDefault();

                                    if(!contextMenuPosition.visible) {
                                        document.addEventListener(`click`, function onClickOutside() {
                                            setContextMenuPosition(contextMenuInitialPosition);
                                            document.removeEventListener(`click`, onClickOutside);
                                        })
                                    }
                                    setContextMenuPosition({
                                        record,
                                        visible: true,
                                        x: event.clientX,
                                        y: event.clientY
                                    });
                                },
                            };
                        }}
                        toolBarRender={() => [
                            <Button key="button" type="primary" onClick={() => { setOpenNewDriverModal(true) }}>
                                <PlusOutlined /> Nuevo driver
                            </Button>
                        ]}
                    />
                </ProCard>
            </AppLayout>
        </>
    );
};

export default Drivers;