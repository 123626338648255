
import axios from "axios";

const api = axios.create({
    baseURL: 'https://integraciones-labs.titask.com/api/integrations/v1/',
    headers: {
        "Accept": 'application/json',
        "Content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "Authorization": "R4qLIFZLICafC4WQzEwLhBNUvKO",
        "country": "CL",
        "lang": "es"
    },
})

const api2 = axios.create({
    baseURL: 'https://integraciones-labs.titask.com/api/integrations/v1/',
    headers: {
        "Accept": 'application/json',
        "Content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Authorization": "R4qLIFZLICafC4WQzEwLhBNUvKO",
        "country": "CL",
        "lang": "es"
    },
})

const SearchFiles = {

    findFiles: async (body: any, rol: any) => {
        try {
            const response = await api.get(`buk/employe/${rol === 'ADMIN_BUK' ? 'filesAMD' : 'files'}/${body}`, body);
            const userData = response.data.files;
            return {
                success: true,
                data: userData,
                userId: response.data.userID
            }
        } catch (err: any) {
            return {
                success: false,
                error: err?.response?.data
            }
        }
    },

    downloadFile: async (body: any) => {
        try {
            const response = await api.get(`buk/employe/files-dw/${body.id}/${body.idUser}`, body);
            const userData = response.data;
            return {
                success: true,
                data: userData,
                userId: response.data
            }
        } catch (err: any) {
            return {
                success: false,
                error: err?.response?.data
            }
        }
    },

    uploadFile: async (idUser: any, data: any) => {
        try {
            const response = await api2.post(`buk/employe/files-up/${idUser}`, data);
            const userData = response.data;
            return {
                success: true,
                data: userData,
                userId: response.data
            }
        } catch (err: any) {
            return {
                success: false,
                error: err?.response?.data
            }
        }
    },

};

export default SearchFiles;