import React, {ReactNode, useEffect, useRef, useState} from 'react';
import AppLayout from "../../components/Layouts/App/App";
import User from "../../models/User";
import {AssignOrderService} from "./services/AssignOrderService";
import {useSelector} from "react-redux";
import {Button, message, Tag} from "antd";
import {ActionType, ProCard, ProColumns, ProTable} from "@ant-design/pro-components";
import {AvailableOrder} from "./models/AvailableOrder";
import AssignDriverModal from "./components/AssignDriverModal";
import {UserService} from "../Users/services/UserService";
import {Carrier} from "../../models/Carrier";
import FilterButton from "../../components/FilterButton/FilterButton";
import StoreFilterModal from "./components/StoreFilterModal";

const columns: ProColumns<AvailableOrder>[] = [
    {
        title: '# ORDEN',
        dataIndex: 'orderClient',
        width: 210,
        copyable: true,
        render: text => <b style={{ color: 'rgb(135 130 130)' }}>{text}</b>
    },
    {
        title: 'SEQ. ID',
        dataIndex: 'seqId',
        ellipsis: true,
        width: 180,
    },
    {
        title: 'STATUS',
        dataIndex: 'status',
        ellipsis: true,
        width: 150,
    },
    {
        title: 'TIENDA',
        dataIndex: 'store',
        ellipsis: true,
        width: 300
    },
];

const SmuAssignOrder = (): JSX.Element => {
    const [messageApi, messageContextHolder] = message.useMessage();
    const authUser = useSelector((state: any) => state.auth);
    const [drivers, setDrivers] = useState<User[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [openAssignDriverModal, setOpenAssignDriverModal] = useState(false);
    const [selectedCarrierCodes, setSelectedCarrierCodes] = useState<string[]>([]);
    const [carriers, setCarriers] = useState<Carrier[]>([]);
    const ref = useRef<ActionType>();
    const [initCarrier, setInitCarrier] = useState(false);
    const [initState, setInitState] = useState(false);
    const [openStoreFilterModal, setOpenStoreFilterModal] = useState(false);
    const [orders, setOrders] = useState<AvailableOrder[]>([]);

    useEffect(() => {
        setSelectedRowKeys([]);

        const init = async () => {

            setSelectedCarrierCodes(authUser.carriers);

            const driversResponse = await AssignOrderService.getDrivers(authUser.token);

            if(driversResponse.success) {
                setDrivers(driversResponse.data);
            }else {
                messageApi.error(driversResponse.error?.response?.data?.data?.message || 'Ups, ocurrió un error inesperado, por favor inténtelo nuevamente.', 3.5);
            }
            setInitState(true);
        }

        init();
    }, []);

    const getTitle = (): string | ReactNode => {
        if(!initCarrier) {
            return 'Lista de órdenes pendientes de asignación';
        }

        const selectedCarriersObj = carriers.filter((carrier) => {
            return selectedCarrierCodes.includes(carrier.id);
        });

        if(selectedCarrierCodes.length > 0) {
            let tiendas = '';

            if(selectedCarriersObj.length <= 1) {
                tiendas =  selectedCarriersObj.map((carrier) => carrier.name).join(', ');
            }else{
                tiendas = `${selectedCarrierCodes.length} tiendas seleccionadas`;
            }

            return (
                    <>Lista de órdenes pendientes de asignación
                        <Tag color="blue" style={{ marginLeft: '5px' }}>
                            Tienda: {tiendas}
                            {
                                !authUser.carrierId
                                    && (<FilterButton size="12px" action={() => { setOpenStoreFilterModal(true) }}/>)
                            }
                        </Tag>
                    </>
            );
        }else {
            return (
                <>Lista de órdenes pendientes de asignación
                    <Tag color="default" style={{ marginLeft: '5px' }}>
                        Tienda: todas
                        {
                            !authUser.carrierId
                            && (<FilterButton size="12px" action={() => { setOpenStoreFilterModal(true) }}/>)
                        }
                    </Tag>
                </>
            );
        }
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const getCheckboxProps = (record: AvailableOrder) => {
        let isDisabled = false;

        if(selectedRowKeys.length > 0) {
            const firstSeqId = selectedRowKeys[0];
            const firstSelected = orders.find((order: AvailableOrder) => {
                return order.seqId === firstSeqId;
            });

            if(firstSelected) {
                isDisabled = (firstSelected.store.toUpperCase() !== record.store.toUpperCase());
            }else {
                isDisabled = false;
            }
        }

        return {
            disabled: isDisabled,
            key: record.seqId
        };
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps
    };

    const hasSelected = selectedRowKeys.length > 0;

    const assignDriverProcess = async (documentDriver: string | null, vehicle: string | null) => {

        if(!documentDriver) {
            messageApi.error('El usuario seleccionado no presenta registrado el número de documento.', 3.5);
            return;
        }

        if(!vehicle) {
            messageApi.error('El usuario seleccionado no presenta registrado el número de placa del vehículo.', 3.5);
            return;
        }

        const seqIds = selectedRowKeys.map((key) => `${key}`);

        const assignResponse = await AssignOrderService.assignDriver(documentDriver, vehicle, seqIds);

        if(assignResponse.success) {
            messageApi.success('Se realizó el proceso satisfactoriamente.', 3.5);
            const table = ref.current as any;
            table.reload();
            setOpenAssignDriverModal(false);
            setSelectedRowKeys([]);
        }else{
            messageApi.error('Ups, ocurrió un error inesperado, por favor inténtelo nuevamente.', 3.5);
        }
    }

    const filterOrders = (newCarrierCodes: string[]) => {
        setSelectedCarrierCodes(newCarrierCodes);
        setOpenStoreFilterModal(false);
        setTimeout(() => {
            const table = ref.current as any;
            table.reload();
        }, 100);
    }

    return (
        <AppLayout title={getTitle()}>
            {messageContextHolder}

            <AssignDriverModal
                open={openAssignDriverModal}
                submit={assignDriverProcess}
                setOpen={setOpenAssignDriverModal}
                drivers={drivers}
                orderIds={selectedRowKeys}
                orders={orders}
                carriers={carriers}
            />

            <StoreFilterModal open={openStoreFilterModal} submit={filterOrders} setOpen={setOpenStoreFilterModal} carriers={carriers} initValue={selectedCarrierCodes}/>

            <ProCard
                style={{
                    minHeight: 350,
                }}
            >
                <ProTable<AvailableOrder>
                    actionRef={ref}
                    rowSelection={rowSelection}
                    size="small"
                    columns={columns}
                    cardBordered
                    request={async (params = {}, sort, filter) => {
                        const keyWord = (params.keyword || '').trim().toUpperCase();

                        let carriersFilter: string[];

                        let localCarriers: Carrier[] = [];

                        if(!initCarrier) {
                            const getCarriersResponse = await UserService.getCarriers();

                            if (getCarriersResponse.success) {
                                localCarriers = getCarriersResponse.data;

                                if(authUser.appRoleId !== 'ADMIN_SMU') {
                                    localCarriers = localCarriers.filter((carrier) => {
                                        return authUser.carriers.includes(carrier.id);
                                    });
                                }

                                setCarriers(localCarriers);


                            } else {
                                console.error(getCarriersResponse);
                            }

                            setInitCarrier(true);
                        }else {
                            localCarriers = carriers;
                        }

                        if(!initState) {
                            carriersFilter = authUser.carriers;
                        }else{
                            carriersFilter = selectedCarrierCodes;
                        }

                        const ordersResponse = await AssignOrderService.getAvailableOrders();

                        if(ordersResponse.success) {

                            let orders = ordersResponse.data || [];

                            if(keyWord) {
                                orders = orders.filter((order: AvailableOrder) => {
                                    return order.orderClient.toUpperCase().indexOf(keyWord) !== -1 || order.seqId.toUpperCase().indexOf(keyWord) !== -1;
                                });
                            }

                            if(carriersFilter.length > 0) {
                                const carriersCheck = localCarriers.filter((carrier) => {
                                    return carriersFilter.includes(carrier.id);
                                }).map((carrier) => carrier.name.toUpperCase());

                                if(carriersCheck.length > 0) {
                                    orders = orders.filter((order: AvailableOrder) => {
                                        return carriersCheck.includes(order.store.toUpperCase());
                                    });
                                }
                            }else {
                                if(authUser.appRoleId !== 'ADMIN_SMU') {
                                    orders = [];
                                }
                            }

                            setOrders(orders);

                            return {
                                success: true,
                                data: orders,
                                total: orders.length
                            };
                        }

                        setOrders([]);

                        return {
                            success: false,
                            data: [],
                            total: 0
                        };
                    }}
                    editable={{
                        type: 'multiple',
                    }}
                    rowKey="seqId"
                    search={false}
                    options={{
                        fullScreen: true,
                        reload: true,
                        setting: false,
                        density: false,
                        search: true
                    }}
                    pagination={{
                        pageSize: 12,
                        onChange: (page) => console.log(page),
                    }}
                    dateFormatter="string"
                    toolBarRender={() => [
                        <Button key="button" type="primary" onClick={() => { setOpenAssignDriverModal(true) }} disabled={!hasSelected}>
                            Asignar driver
                        </Button>
                    ]}
                    scroll={{ x: 600 }}
                />
            </ProCard>
        </AppLayout>
    );
};

export default SmuAssignOrder;