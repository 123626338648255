import React, {ReactNode, useEffect, useState} from 'react';
import {Avatar, Descriptions, Drawer, Image, List, Steps, Timeline} from "antd";
import {Order} from "../models/Order";
import {OrderItem} from "../models/OrderItem";

import './DetailDrawer.css';
import moment from "moment";

interface DetailDrawerSchema {
    open: boolean;
    setOpen: (open: boolean) => void;
    order: Order
}

interface StepSchema {
    title: string;
    description: string | ReactNode;
}

const defaultStepValue: StepSchema[] = [
    {
        title: 'Order create',
        description: '',
    },
    {
        title: 'Order shipped',
        description: '',
    },
    {
        title: 'Order in route',
        description: '',
    },
    {
        title: 'Order arrival',
        description: '',
    },
];

const DetailDrawer = ({ open, setOpen, order }: DetailDrawerSchema): JSX.Element => {
    const [current, setCurrent] = useState(0);
    const [steps, setSteps] = useState(defaultStepValue);
    const [status, setStatus] = useState('');

    useEffect(() => {

        if(open) {
            const dataStatusCreate = order.history.find((history) => {
                return ['job'].includes(history.status.toLowerCase());
            });

            const dataStatusShipped = order.history.find((history) => {
                return ['shipped'].includes(history.status.toLowerCase());
            });

            const dataStatusArrivedToDestination = order.history.find((history) => {
                return ['arrived_to_destination'].includes(history.status.toLowerCase());
            });

            const dataStatusOrderDelivered = order.history.find((history) => {
                return ['delivered'].includes(history.status.toLowerCase());
            });

            const newStepValues = structuredClone(defaultStepValue);
            const lastStatus = order.history.length > 0 ? order.history[0].status.toUpperCase() : '';
            let lastStep = 0;

            if(dataStatusCreate) {
                newStepValues[0].description = <small>{moment(dataStatusCreate.date).format('DD/MM/YYYY HH:mm')}</small>
                lastStep++;
            }

            if(dataStatusShipped) {
                newStepValues[1].description = <small>{moment(dataStatusShipped.date).format('DD/MM/YYYY HH:mm')}</small>
                lastStep++;
            }

            if(dataStatusArrivedToDestination) {
                newStepValues[2].description = <small>{moment(dataStatusArrivedToDestination.date).format('DD/MM/YYYY HH:mm')}</small>
                lastStep++;
            }

            if(dataStatusOrderDelivered) {
                newStepValues[3].description = <small>{moment(dataStatusOrderDelivered.date).format('DD/MM/YYYY HH:mm')}</small>
                lastStep++;
            }

            setCurrent(lastStep);
            setSteps(newStepValues);
            setStatus(lastStatus);
        }else{
            setCurrent(0);
            setSteps(structuredClone(defaultStepValue));
            setStatus('');
        }

    }, [open])

    const onClose = () => {
        setOpen(false);
    };

    return (
        <Drawer
            title={<>Nro. orden: <span style={{ color: 'darkblue' }}>{order.orderClient}</span> - Status: <span style={{ color: 'darkblue' }}>{status}</span></>}
            width={720}
            onClose={onClose}
            open={open}
            bodyStyle={{ paddingBottom: 80 }}
            destroyOnClose
        >
            <Steps size="small" current={current} items={steps} />

            <List
                size="small"
                style={{ marginTop: '25px' }}
                header={<b style={{ color: 'rgb(135 130 130)' }}>Detalle del cliente</b>}
                footer={false}
                bordered
                dataSource={["dummyItem"]}
                renderItem={(item: string) => (
                    <List.Item>
                        <Descriptions title={false} layout="vertical">
                            <Descriptions.Item label="Dirección">{ order.address }</Descriptions.Item>
                            <Descriptions.Item label="Tienda Arrival">{ order.store }</Descriptions.Item>
                            <Descriptions.Item label="Teléfono">{ order.userPhoneNumber }</Descriptions.Item>
                            <Descriptions.Item label="Nombres">{ order.userName }</Descriptions.Item>
                            <Descriptions.Item label="Tipo de documento">RUT</Descriptions.Item>
                            <Descriptions.Item label="Número de documento">{ order.userIdentificationNumber }</Descriptions.Item>
                            <Descriptions.Item label="Correo">{ order.userEmail }</Descriptions.Item>
                        </Descriptions>
                    </List.Item>
                )}
            />


            <List<OrderItem>
                size="small"
                style={{ marginTop: '25px' }}
                header={<b style={{ color: 'rgb(135 130 130)' }}>Detalle de productos</b>}
                footer={false}
                bordered
                dataSource={order.items}
                pagination={{
                    onChange: (page) => {
                        console.log(page);
                    },
                    pageSize: 5,
                }}
                renderItem={(item: OrderItem) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar style={{ marginTop: '4px' }} src={item.photoUrl} />}
                            title={`${item.name}`}
                            description={`📦 Cantidad: ${item.quantity} 🏷 Precio: ${item.price}`}
                        />

                    </List.Item>
                )}
            />

            {/*<List*/}
            {/*    size="small"*/}
            {/*    style={{ marginTop: '25px' }}*/}
            {/*    header={<b style={{ color: 'rgb(135 130 130)' }}>Detalles adicionales</b>}*/}
            {/*    footer={false}*/}
            {/*    bordered*/}
            {/*    dataSource={["dummyItem"]}*/}
            {/*    renderItem={(item: string) => (*/}
            {/*        <List.Item>*/}
            {/*            <Descriptions title={false} layout="vertical">*/}
            {/*                <Descriptions.Item label="Cantidad de bultos">{ 5 }</Descriptions.Item>*/}
            {/*                <Descriptions.Item label="Bultos (fotos)">*/}
            {/*                    <span style={{ marginRight: '5px' }}>*/}
            {/*                        <Image*/}
            {/*                            width={50}*/}
            {/*                            src="https://d1lofqbqbj927c.cloudfront.net/monumental/2018/10/24190404/agencia-aduanal-en-costa-rica.jpg"*/}
            {/*                        />*/}
            {/*                    </span>*/}

            {/*                    <span style={{ marginRight: '5px' }}>*/}
            {/*                        <Image*/}
            {/*                            width={50}*/}
            {/*                            src="https://www.acaciatec.com/wp-content/uploads/2021/01/packages-986958_1920-1.jpg"*/}
            {/*                        />*/}
            {/*                    </span>*/}

            {/*                    <span style={{ marginRight: '5px' }}>*/}
            {/*                        <Image*/}
            {/*                            width={50}*/}
            {/*                            src="https://www.elfinancierocr.com/resizer/9iQWi0H72ZexS1qqR8J7hIcdE0I=/1440x0/filters:format(jpg):quality(70)/cloudfront-us-east-1.images.arcpublishing.com/gruponacion/O2V36S677BFFTAGVAYBM3K7LYQ.JPG"*/}
            {/*                        />*/}
            {/*                    </span>*/}
            {/*                </Descriptions.Item>*/}
            {/*            </Descriptions>*/}
            {/*        </List.Item>*/}
            {/*    )}*/}
            {/*/>*/}
        </Drawer>
    );
};

export default DetailDrawer;