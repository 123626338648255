import React, {useEffect, useRef, useState} from 'react';
import AppLayout from "../../components/Layouts/App/App";
import {Button, message, Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {ActionType, ProCard, ProColumns, ProTable} from "@ant-design/pro-components";
import {ContextMenuSchema} from "../SmuOrders/components/ContextMenu";
import User from "../../models/User";
import UserContextMenu from "./components/UserContextMenu";
import NewUserModal from "./components/NewUserModal";
import {PlusOutlined} from "@ant-design/icons";
import {UserService} from "./services/UserService";
import {Carrier} from "../../models/Carrier";
import EditUserModal from "./components/EditUserModal";
import AuthAction from "../../redux/actions/authActions/actions";
import {AuthService} from "../../services/AuthService";

const columns: ProColumns<User>[] = [
    {
        title: 'ID',
        dataIndex: 'id',
        width: 70,
        render: text => <b>{text}</b>
    },
    {
        title: 'EMAIL',
        dataIndex: 'email',
        ellipsis: true,
        width: 250
    },
    {
        title: 'NOMBRES',
        dataIndex: 'name',
        ellipsis: true,
        width: 250
    },
    {
        title: 'APELLIDOS',
        dataIndex: 'lastname',
        ellipsis: true,
        width: 250
    },
    {
        title: 'ROL',
        dataIndex: 'appRoleId',
        width: 200,
        render: text => {
            return (<>
                {
                    text === 'ADMIN_SMU'
                        ? (<span>Administrador</span>)
                        : (text === 'CONTROL_TOWER_SMU' ? <span>Torre de control</span> : <span>Unknown</span>)
                }
            </>);
        }
    }
];

const contextMenuInitialPosition: ContextMenuSchema = {
    record: undefined,
    visible: false,
    x: 0,
    y: 0
}

const Users = (): JSX.Element => {
    const [messageApi, messageContextHolder] = message.useMessage();
    const [modal, modalContextHolder] = Modal.useModal();
    const authUser = useSelector((state: any) => state.auth);
    const ref = useRef<ActionType>();
    const dispatch = useDispatch();
    const [contextMenuPosition, setContextMenuPosition] = useState<ContextMenuSchema>(contextMenuInitialPosition);
    const [openNewUserModal, setOpenNewUserModal] = useState(false);
    const [openEditUserModal, setOpenEditUserModal] = useState(false);
    const [userSelected, setUserSelected] = useState<User | null>(null);
    const [isDisabledEditUserModal, setIsDisabledEditUserModal] = useState(false);
    const [carriers, setCarriers] = useState<Carrier[]>([]);

    useEffect(() => {
        const init = async () => {
            const getCarriersResponse = await UserService.getCarriers();

            if (getCarriersResponse.success) {
                setCarriers(getCarriersResponse.data);
            } else {
                console.error(getCarriersResponse);
                messageApi.error(`No se pudieron cargar las tiendas, por favor inténtelo más tarde.`, 3.5);
            }
        }

        init();
    }, []);

    const newUser = async (email: string, name: string, lastname: string, roleId: 'ADMIN_SMU' | 'CONTROL_TOWER_SMU', carriers: string[]) => {
        const createSmuUserResponse = await UserService.createSmuUser(authUser.token, email, name, lastname, roleId, carriers);

        if(createSmuUserResponse.success) {
            messageApi.success(`Se registró el usuario satisfactoriamente.`, 3.5);
            setOpenNewUserModal(false);
            const table = ref.current as any;
            table.reload();
        }else{
            messageApi.error(createSmuUserResponse.error?.response?.data?.data?.message || 'Ups, ocurrió un error inesperado, por favor inténtelo nuevamente.', 3.5);
        }
    }

    const showEditUserModal = async (userSelected: User, isDisabled: boolean) => {
        setIsDisabledEditUserModal(isDisabled);
        const loadingMessageKey = 'loading-message-edit-user';

        messageApi.open({
            key: loadingMessageKey,
            type: 'loading',
            content: 'Obteniendo información...',
            duration: 0
        });

        const userResponse = await UserService.findUserById(authUser.token, userSelected.id);

        messageApi.destroy(loadingMessageKey);

        if(userResponse.success) {
            setUserSelected(userResponse.data as User);
            setOpenEditUserModal(true);
        }else{
            messageApi.error(userResponse.error?.response?.data?.data?.message || 'Ups, ocurrió un error inesperado, por favor inténtelo nuevamente.', 3.5);
        }

    }

    const editUser = async (id: number, name: string, lastname: string, roleId: string, carriers: string[]) => {
        const updateUser = async () => {
            const editSmuUserResponse = await UserService.updateSmuUser(authUser.token, id, name, lastname, roleId, carriers);

            if(editSmuUserResponse.success) {
                if(id === authUser.id) {
                    const verifyUserSessionResponse = await AuthService.verifySession(authUser.token);
                    if(verifyUserSessionResponse.success) {
                        dispatch(AuthAction.signIn(verifyUserSessionResponse.data));
                    }
                }

                messageApi.success(`Se editó el usuario satisfactoriamente.`, 3.5);
                setOpenEditUserModal(false);
                const table = ref.current as any;
                table.reload();
            }else{
                messageApi.error(editSmuUserResponse.error?.response?.data?.data?.message || 'Ups, ocurrió un error inesperado, por favor inténtelo nuevamente.', 3.5);
            }
        }

        await updateUser();
    }

    return (
        <>
            <UserContextMenu contextMenuPosition={contextMenuPosition} editUser={showEditUserModal}/>

            <AppLayout title="Mantenimiento de usuarios">
                {messageContextHolder}
                {modalContextHolder}

                <NewUserModal open={openNewUserModal} submit={newUser} setOpen={setOpenNewUserModal} carriers={carriers}/>
                {
                    userSelected && (<EditUserModal user={userSelected} open={openEditUserModal} submit={editUser} setOpen={setOpenEditUserModal} carriers={carriers} disabled={isDisabledEditUserModal}/>)
                }

                <ProCard
                    style={{
                        minHeight: 350,
                    }}
                >
                    <ProTable<User>
                        scroll={{ x: 600 }}
                        actionRef={ref}
                        size="small"
                        columns={columns}
                        cardBordered
                        request={async (params = {}, sort, filter) => {
                            const keyWord = (params.keyword || '').trim().toUpperCase();

                            const getSmuUsersResponse = await UserService.getSmuUsers(authUser.token);

                            if(getSmuUsersResponse.success) {

                                let users = getSmuUsersResponse.data.sort((a: User, b: User) => {
                                    return b.id - a.id;
                                });

                                if(keyWord) {
                                    users = users.filter((user: User) => {
                                        return user.email.toUpperCase().indexOf(keyWord) !== -1 || user.name.toUpperCase().indexOf(keyWord) !== -1 || user.lastname.toUpperCase().indexOf(keyWord) !== -1;
                                    });
                                }

                                return {
                                    success: true,
                                    data: users,
                                    total: users.length
                                };
                            }else{
                                messageApi.error(getSmuUsersResponse.error?.response?.data?.data?.message || 'Ups, ocurrió un error inesperado, por favor inténtelo nuevamente.', 3.5);
                            }

                            return {
                                success: false,
                                data: [],
                                total: 0
                            };
                        }}
                        editable={{
                            type: 'multiple',
                        }}
                        rowKey="id"
                        search={false}
                        options={{
                            fullScreen: true,
                            reload: true,
                            setting: false,
                            density: false,
                            search: true
                        }}
                        pagination={{
                            pageSize: 12,
                            onChange: (page) => console.log(page),
                        }}
                        dateFormatter="string"
                        onRow={(record, rowIndex) => {
                            return {
                                onContextMenu: (event) => {
                                    event.preventDefault();

                                    if(!contextMenuPosition.visible) {
                                        document.addEventListener(`click`, function onClickOutside() {
                                            setContextMenuPosition(contextMenuInitialPosition);
                                            document.removeEventListener(`click`, onClickOutside);
                                        })
                                    }
                                    setContextMenuPosition({
                                        record,
                                        visible: true,
                                        x: event.clientX,
                                        y: event.clientY
                                    });
                                },
                            };
                        }}
                        toolBarRender={() => [
                            <Button key="button" type="primary" onClick={() => { setOpenNewUserModal(true) }}>
                                <PlusOutlined /> Nuevo usuario
                            </Button>
                        ]}
                    />
                </ProCard>
            </AppLayout>
        </>
    );
};

export default Users;