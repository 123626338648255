import React, { useState } from 'react';
import moment from 'moment';
import { ProCard } from "@ant-design/pro-components";
import AuthAction from "../../redux/actions/authActions/actions";
import SearchFilesService from "./services/SearchService";
import AppLayout from "../../components/Layouts/App/App";
import { FormLayout } from "antd/es/form/Form";
import { Button, Checkbox, Form, Input, message, Spin, DatePicker, Select, List, Avatar, UploadProps, UploadFile } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import './registerU.css';
import { Col, Row, Space } from 'antd';
import Icon from '@ant-design/icons/lib/components/Icon';
import { CloudDownloadOutlined, InboxOutlined, SearchOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import { RcFile } from 'antd/es/upload';
const LAYOUT_TYPE_HORIZONTAL = 'horizontal';



const waitTime = (time: number = 100) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};


//Documentación: https://procomponents.ant.design/en-US/components/form

const SearchFiles = (): JSX.Element => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [datalist, setDatalist] = useState([] as any);
    const [userId, serUserId] = useState('' as any);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState(false);
    const { appRoleId } = useSelector((state: any) => state.auth);
    const onFinish = async (values: any) => {
        if (!loading) {

            const loadingMessageKey = 'loading-message';
            messageApi.open({
                key: loadingMessageKey,
                type: 'loading',
                content: 'Search Files...',
                duration: 0
            });
            setLoading(true);

            const searchResponse: any = await SearchFilesService.findFiles(values.rut, appRoleId);

            setLoading(false);

            messageApi.destroy(loadingMessageKey);

            if (searchResponse.success) {
                if (searchResponse.data) {
                    setDatalist(searchResponse.data?.employee_files)
                    serUserId(searchResponse.userId)
                }
            } else {
                messageApi.error(searchResponse.error?.message || 'Hubo un error al Buscar el file del usuario, por favor inténtalo nuevamente.', 3.5);
            }
        }
    };

    const downloadFile = async (values: any, nameFile: any) => {
        if (!loading) {

            const loadingMessageKey = 'loading-message';
            messageApi.open({
                key: loadingMessageKey,
                type: 'loading',
                content: 'Donwload Files...',
                duration: 0
            });
            setLoading(true);
            const searchResponse: any = await SearchFilesService.downloadFile({ id: values, idUser: userId });

            setLoading(false);

            messageApi.destroy(loadingMessageKey);

            if (searchResponse.success) {
                const linkSource = `data:application/pdf;base64,${searchResponse.data}`;
                const downloadLink = document.createElement("a");
                const fileName = nameFile;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();

            } else {
                messageApi.error(searchResponse.error?.data?.message || 'Hubo un error al descargar el archivo, por favor inténtalo nuevamente.', 3.5);
            }
        }
    };

    const handleUpload = async () => {
        const loadingMessageKey = 'loading-message-valor';
        setUploading(true);
        setLoading(true);
        messageApi.open({
            key: loadingMessageKey,
            type: 'loading',
            content: 'Upload Files...',
            duration: 0
        });
        for (const file of fileList) {
            const formData = new FormData();
            formData.append('file', file as RcFile);
            const searchResponse: any = await SearchFilesService.uploadFile(userId, formData);
            console.log(searchResponse)
            if (searchResponse?.data?.employee_file) {
                const fileData = searchResponse?.data?.employee_file;
                datalist.unshift({ filename: fileData.original_filename, created_at: fileData.created_at, file_id: fileData.id })
                setDatalist(datalist)
            }
        }

        setLoading(false);
        setUploading(false);
        messageApi.destroy(loadingMessageKey);
    };

    const props: UploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };
    const cleanForm = () => {
        form.resetFields();
        setDatalist([])
        serUserId('')
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        messageApi.error('Por favor corrija los errores mencionados', 3.5);
    };
    return (
        <>
            {contextHolder}
            <AppLayout title="Documentos de Usuario">
                <ProCard
                    style={{
                        minHeight: 350,
                        paddingLeft: '15%'
                    }}
                >
                    <Form
                        form={form}
                        name="login-form-rg"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >

                        <Form.Item
                            name="rut"
                            rules={[
                                { required: true, message: 'El Rut es requerido' },
                            ]}
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Rut"
                            />
                        </Form.Item>
                        <Form.Item
                            className='buttons-style'
                        >
                            <Button type="primary" htmlType="submit" className="login-form-button-rg">
                                BUSCAR
                            </Button>
                        </Form.Item>
                        <Form.Item
                            className='buttons-style'>
                            <Button type="primary" onClick={cleanForm} className="login-form-button-rg">
                                Limpiar
                            </Button>
                        </Form.Item>
                        <List<any>
                            size="small"
                            style={{ marginTop: '25px' }}
                            header={<b style={{ color: 'rgb(135 130 130)' }}>Detalle de Archivos</b>}
                            footer={false}
                            bordered
                            dataSource={datalist}
                            pagination={{
                                onChange: (page) => {
                                    console.log(page);
                                },
                                pageSize: 5,
                            }}
                            renderItem={(item: any) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar style={{ marginTop: '4px' }} src={'https://raw.githubusercontent.com/PKief/vscode-material-icon-theme/main/logo.png'} />}
                                        title={`Id:${item.file_id}`}
                                        description={`🗂️ Archivo: ${item.filename} 🏷 Creado: ${item.created_at}`}
                                    />
                                    <Button onClick={() => downloadFile(item.file_id, item.filename)} icon={<CloudDownloadOutlined />}>
                                    </Button>

                                </List.Item>
                            )}
                        />

                        <br />
                        {userId !== '' &&
                            <>
                                <Dragger {...props} >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Cargar Archivos Permitidos</p>
                                    <p className="ant-upload-hint">
                                        Revisar previamente sus archivos por seguridad de los usuarios.
                                    </p>
                                </Dragger>
                                <Button type="primary" loading={loading} onClick={handleUpload} className="login-form-button-rg">
                                    Guardar Archivos
                                </Button>
                            </>

                        }


                    </Form>
                </ProCard>
            </AppLayout >
        </>
    );
};

export default SearchFiles;