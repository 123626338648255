import React from 'react';
import {ProForm, ProFormSelect} from "@ant-design/pro-components";
import {Modal} from "antd";
import {Carrier} from "../../../models/Carrier";

interface StoreFilterModalSchema {
    open: boolean;
    submit: (value: string[]) => void;
    setOpen: (value: boolean) => void;
    carriers: Carrier[];
    initValue: string[];
}

const StoreFilterModal = ({open, submit, setOpen, carriers, initValue}: StoreFilterModalSchema): JSX.Element => {
    const getCarrierList = () => {
        return carriers.map((carrier) => {
            return {
                value: carrier.id,
                label: carrier.name
            }
        });
    }


    return (
        <Modal
            title="Filtrar resumen por tienda"
            open={open}
            destroyOnClose
            onCancel={() => { setOpen(false) }}
            closable
            keyboard
            footer={null}
        >
            <hr style={{ border: '0', borderTop: '1px solid #c3c3c3'}}/>

            <ProForm<any>
                style={{ marginTop: '20px' }}
                onFinish={async (values: any) => {
                    submit(values.store_input || []);
                }}
                submitter={{
                    searchConfig: {
                        submitText: 'Filtrar'
                    },
                    resetButtonProps: {
                        style: {
                            display: 'none',
                        },
                    },
                    submitButtonProps: {
                        style: {
                            marginLeft: '-8px',
                        }
                    },
                }}
            >
                <ProForm.Group>
                    <ProFormSelect
                        options={getCarrierList()}
                        width="xl"
                        name="store_input"
                        label="Por tienda"
                        placeholder="-TODAS LAS TIENDAS-"
                        initialValue={initValue}
                        mode="multiple"
                    />
                </ProForm.Group>
            </ProForm>
        </Modal>
    );
};

export default StoreFilterModal;