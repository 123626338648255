import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, message, Spin, DatePicker, Select } from "antd";
import { AuthService } from "./services/AuthService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import AuthAction from "../../redux/actions/authActions/actions";

import './register.css';
import moment from 'moment';

const Index = (): JSX.Element => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const onFinish = async (values: any) => {
        if (!loading) {
            console.log(values.fechnac?.['$d'])
            const sendBody = {
                first_name: values.nombres,
                surname: values.apellidos,
                second_surname: values.nombres,
                rut: values.rut,
                nationality: values.cmbNacionalidad,
                gender: values.cmbGenero,
                civil_status: values.cmbEstadoCivil,
                birthday: values.fechnac ? moment(values.fechnac?.['$d']).format('YYYY-MM-DD') : '',
                email: values.email,
                personal_email: values.email,
                location_id: 45,
                address: values.direccion,
                city: values.cmbCiudad,
                payment_method: values.cmbPago,
                bank: values.cmbBanco,
                account_type: values.cmbCuenta
            }
            const loadingMessageKey = 'loading-message';
            messageApi.open({
                key: loadingMessageKey,
                type: 'loading',
                content: 'Comprobando credenciales...',
                duration: 0
            });
            setLoading(true);

            const loginResponse = await AuthService.saveUser(sendBody);

            setLoading(false);

            messageApi.destroy(loadingMessageKey);

            if (loginResponse.success) {
                messageApi.success('Autenticó sus credenciales con éxito.', 3.5);
                dispatch(AuthAction.signIn(loginResponse.data));
                navigate('/');
            } else {
                messageApi.error(loginResponse.error?.data?.message || 'Hubo un error al intentar autenticar al usuario, por favor inténtalo nuevamente.', 3.5);
            }
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        messageApi.error('Por favor corrija los errores mencionados', 3.5);
    };

    return (
        <div className="login-page-rg">
            {contextHolder}
            <Spin spinning={loading}>
                <div className="login-box-rg">
                    <div className="illustration-wrapper-rg">
                        <img src="https://titaskdomain.s3.us-east-2.amazonaws.com/files/images/sign-background-lg.png" alt="Register" />
                    </div>
                    <Form
                        name="login-form-rg"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <p className="form-title-rg">Bienvenido a TouchLatam </p>
                        <Form.Item
                            name="nombres"
                            rules={[
                                { required: true, message: 'El nombre es requerido' },
                            ]}
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Nombres"
                            />
                        </Form.Item>

                        <Form.Item
                            name="apellidos"
                            rules={[
                                { required: true, message: 'La apellido es requerido' },
                            ]}
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Apellidos"
                            />
                        </Form.Item>

                        <Form.Item
                            name="rut"
                            rules={[
                                { required: true, message: 'El Rut es requerido' },
                            ]}
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Rut"
                            />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            rules={[
                                { required: true, message: 'El correo electrónico es requerido' },
                                { type: 'email', message: 'Debe de ingresar un correo electrónico válido' },

                            ]}
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Email"
                            />
                        </Form.Item>
                        <Form.Item
                            name="direccion"
                            rules={[
                                { required: true, message: 'La direccion es requerido' },
                            ]}
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Direccion"

                            />
                        </Form.Item>
                        <Form.Item
                            name="fechnac"
                            rules={[
                                { required: true, message: 'La fecha nacio es requerido' },
                            ]}
                        >
                            <DatePicker
                                placeholder="Fecha de nacimiento"
                            />
                        </Form.Item>
                        <Form.Item
                            name='cmbNacionalidad'
                            rules={[
                                { required: true, message: 'La nacionalidad es requerido' },
                            ]}
                        >
                            <Select
                                placeholder="Seleccione Nacionalidad    "
                                options={[
                                    { value: 'Chilena', label: 'Chilena' },
                                    { value: 'Peruana', label: 'Peruana' }
                                ]}
                            />
                        </Form.Item>

                        <Form.Item
                            name='cmbCiudad'
                            rules={[
                                { required: true, message: 'Ciudad' },
                            ]}
                        >
                            <Select
                                placeholder="Seleccione Ciudad"
                                options={[
                                    { value: 'Santiago', label: 'Santiago' }
                                ]}
                            />
                        </Form.Item>


                        <Form.Item
                            name='cmbGenero'
                            rules={[
                                { required: true, message: 'Genero' },
                            ]}>
                            <Select
                                placeholder="Seleccione Genero"
                                options={[
                                    { value: 'M', label: 'masculino' },
                                    { value: 'F', label: 'femenino' },
                                    { value: 'otros', label: 'otros' },
                                ]}
                            />
                        </Form.Item>

                        <Form.Item
                            name='cmbEstadoCivil'
                            rules={[
                                { required: true, message: 'Estado Civil' },
                            ]}>
                            <Select
                                placeholder="Seleccione Estado Civil"
                                options={[
                                    { value: 'Soltero', label: 'Soltero' },
                                    { value: 'Casado', label: 'Casado' },
                                    { value: 'Viudo', label: 'Viudo' },
                                    { value: 'Divorciado', label: 'Divorciado' },
                                ]}
                            />
                        </Form.Item>



                        <Form.Item
                            name='cmbPago'
                            rules={[
                                { required: true, message: 'Pago' },
                            ]}>
                            <Select
                                placeholder="Seleccione Metodo Pago"
                                options={[
                                    { value: 'Transferencia Bancaria', label: 'Transferencia Bancaria' },
                                ]}
                            />
                        </Form.Item>

                        <Form.Item
                            name='cmbBanco'
                            rules={[
                                { required: true, message: 'Banco' },
                            ]}>
                            <Select
                                placeholder="Seleccione Banco"
                                options={[
                                    { value: 'Santander', label: 'Santander' },
                                ]}
                            />
                        </Form.Item>

                        <Form.Item
                            name='cmbCuenta'
                            rules={[
                                { required: true, message: 'Cuenta' },
                            ]}>
                            <Select
                                placeholder="Seleccione Tipo Cuenta"
                                options={[
                                    { value: 'Corriente', label: 'Corriente' },
                                    { value: 'Ahorros', label: 'Ahorros' },
                                ]}
                            />
                        </Form.Item>


                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button-rg">
                                REGISTAR
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </div>
    );
};

export default Index;