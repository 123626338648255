import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {ActionType, ProCard, ProColumns, ProTable} from "@ant-design/pro-components";
import {Order} from "./models/Order";
import AppLayout from "../../components/Layouts/App/App";
import {OrderService} from "./services/OrderService";
import HistoryDrawer from "./components/HistoryDrawer";
import ContextMenu, {ContextMenuSchema} from "./components/ContextMenu";
import DetailDrawer from "./components/DetailDrawer";
import {useSelector} from "react-redux";
import {Carrier} from "../../models/Carrier";
import {UserService} from "../Users/services/UserService";
import {Tag} from "antd";
import FilterButton from "../../components/FilterButton/FilterButton";
import StoreFilterModal from "./components/StoreFilterModal";

const columns: ProColumns<Order>[] = [
    {
        title: '# ORDEN',
        dataIndex: 'orderClient',
        width: 210,
        copyable: true,
        render: text => <b style={{ color: 'rgb(135 130 130)' }}>{text}</b>
    },
    {
        title: 'STATUS',
        dataIndex: 'status',
        ellipsis: true,
        width: 120,
    },
    {
        title: 'NOMBRES',
        dataIndex: 'userName',
        ellipsis: true,
        width: 300
    },
    {
        title: 'RUT CLIENTE',
        dataIndex: 'userIdentificationNumber',
        ellipsis: true,
        width: 150
    },
    {
        title: 'CORREO ELECTRÓNICO',
        dataIndex: 'userEmail',
        ellipsis: true,
        width: 250
    },
    {
        title: 'VEHÍCULO',
        dataIndex: 'vehicle',
        ellipsis: true,
        width: 150
    },
    {
        title: 'TIENDA',
        dataIndex: 'store',
        ellipsis: true,
        width: 280
    },
];


const contextMenuInitialPosition: ContextMenuSchema = {
    record: undefined,
    visible: false,
    x: 0,
    y: 0
}


const SmuOrders = (): JSX.Element => {
    const authUser = useSelector((state: any) => state.auth);
    const [openHistoryDrawer, setOpenHistoryDrawer] = useState(false);
    const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
    const [openStoreFilterModal, setOpenStoreFilterModal] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState<ContextMenuSchema>(contextMenuInitialPosition);
    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
    const [selectedCarrierCodes, setSelectedCarrierCodes] = useState<string[]>([]);
    const [carriers, setCarriers] = useState<Carrier[]>([]);
    const ref = useRef<ActionType>();
    const [initComponent, setInitComponent] = useState(false);

    const filterOrders = (newCarrierCodes: string[]) => {
        setSelectedCarrierCodes(newCarrierCodes);
        setOpenStoreFilterModal(false);
        setTimeout(() => {
            const table = ref.current as any;
            table.reload();
        }, 100);
    }

    useEffect(() => {
        const init = async () => {

            setSelectedCarrierCodes(authUser.carriers);

            const getCarriersResponse = await UserService.getCarriers();

            if (getCarriersResponse.success) {
                setCarriers(getCarriersResponse.data);
            } else {
                console.error(getCarriersResponse);
            }

            setInitComponent(true);
        }

        init();
    }, []);

    const getTitle = (): string | ReactNode => {
        if(!initComponent) {
            return 'Historial de órdenes';
        }

        const selectedCarriersObj = carriers.filter((carrier) => {
            return selectedCarrierCodes.includes(carrier.id);
        });

        if(selectedCarrierCodes.length > 0) {
            let tiendas = '';

            if(selectedCarriersObj.length <= 1) {
                tiendas =  selectedCarriersObj.map((carrier) => carrier.name).join(', ');
            }else{
                tiendas = `${selectedCarrierCodes.length} tiendas seleccionadas`;
            }

            return (
                <>Historial de órdenes
                    <Tag color="blue" style={{ marginLeft: '5px' }}>
                        Tienda: {tiendas}
                        {
                            !authUser.carrierId
                            && (<FilterButton size="12px" action={() => { setOpenStoreFilterModal(true) }}/>)
                        }
                    </Tag>
                </>
            );
        }else {
            return (
                <>Historial de órdenes
                    <Tag color="default" style={{ marginLeft: '5px' }}>
                        Tienda: todas
                        {
                            !authUser.carrierId
                            && (<FilterButton size="12px" action={() => { setOpenStoreFilterModal(true) }}/>)
                        }
                    </Tag>
                </>
            );
        }
    }

    const showHistory = (orderSelected: Order) => {
        setSelectedOrder(orderSelected);
        setOpenHistoryDrawer(true)
    };

    const showDetail = (orderSelected: Order) => {
        setSelectedOrder(orderSelected);
        setOpenDetailDrawer(true)
    };

    return (
        <>
            <ContextMenu contextMenuPosition={contextMenuPosition} showHistory={showHistory} showDetail={showDetail} />

            <AppLayout title={getTitle()}>

                {
                    selectedOrder && (
                        <>
                            <HistoryDrawer open={openHistoryDrawer} setOpen={setOpenHistoryDrawer} order={selectedOrder} token={authUser.token}/>
                            <DetailDrawer open={openDetailDrawer} setOpen={setOpenDetailDrawer} order={selectedOrder} />
                        </>
                    )
                }

                <StoreFilterModal open={openStoreFilterModal} submit={filterOrders} setOpen={setOpenStoreFilterModal} carriers={carriers} initValue={selectedCarrierCodes}/>

                <ProCard
                    style={{
                        minHeight: 350,
                    }}
                >
                    <ProTable<Order>
                        actionRef={ref}
                        size="small"
                        columns={columns}
                        cardBordered
                        request={async (params = {}, sort, filter) => {
                            const keyWord = (params.keyword || '').trim().toUpperCase();

                            let carriersFilter: string[];

                            if(!initComponent) {
                                carriersFilter = authUser.carriers;
                            }else{
                                carriersFilter = selectedCarrierCodes;
                            }

                            const ordersResponse = await OrderService.getOrders(params.current || 1, params.pageSize || 12, (keyWord.length === 0 ? undefined : keyWord), carriersFilter );

                            if(ordersResponse.success) {

                                return {
                                    success: true,
                                    data: ordersResponse.data?.orders || [],
                                    total: ordersResponse.data?.totalRows || 0
                                };
                            }

                            return {
                                success: false,
                                data: [],
                                total: 0
                            };
                        }}
                        editable={{
                            type: 'multiple',
                        }}
                        rowKey="orderClient"
                        search={false}
                        options={{
                            fullScreen: true,
                            reload: true,
                            setting: false,
                            density: false,
                            search: true
                        }}
                        pagination={{
                            pageSize: 12,
                            onChange: (page) => console.log(page),
                        }}
                        dateFormatter="string"
                        scroll={{ x: 600 }}
                        onRow={(record, rowIndex) => {
                            return {
                                onContextMenu: (event) => {
                                    event.preventDefault();

                                    if(!contextMenuPosition.visible) {
                                        document.addEventListener(`click`, function onClickOutside() {
                                            setContextMenuPosition(contextMenuInitialPosition);
                                            document.removeEventListener(`click`, onClickOutside);
                                        })
                                    }
                                    setContextMenuPosition({
                                        record,
                                        visible: true,
                                        x: event.clientX,
                                        y: event.clientY
                                    });
                                },
                            };
                        }}
                    />
                </ProCard>
            </AppLayout>
        </>
    );
};

export default SmuOrders;