import React, { useEffect, useState } from "react";
import moment from "moment";
import { ProCard } from "@ant-design/pro-components";
import AuthAction from "../../redux/actions/authActions/actions";
import UserService from "./services/UserService";
import AppLayout from "../../components/Layouts/App/App";
import { FormLayout } from "antd/es/form/Form";
import {
    Button,
    Checkbox,
    Form,
    Input,
    message,
    Spin,
    DatePicker,
    Select,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./registerU.css";
import { Col, Row, Space } from "antd";
import SearchFindUser from "../SearchUser/services/SearchService";
const LAYOUT_TYPE_HORIZONTAL = "horizontal";

const waitTime = (time: number = 100) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};

//Documentación: https://procomponents.ant.design/en-US/components/form

const SavePlan = (): JSX.Element => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [tiposRegimenes, setTiposRegimenes] = useState([]);
    const [tiposFondo, setTiposFondo] = useState([]);
    const [idUser, setIdUser] = useState(0);
    const [tiposCompaniaSeg, setTiposCompaniaSeg] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [formPlan] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const onFinish = async (values: any) => {
        if (!loading) {
            const loadingMessageKey = "loading-message";
            messageApi.open({
                key: loadingMessageKey,
                type: "loading",
                content: "Search user...",
                duration: 0,
            });
            setLoading(true);

            const searchResponse = await SearchFindUser.findUser(values.rut);

            setLoading(false);

            messageApi.destroy(loadingMessageKey);

            if (searchResponse.success) {
                console.log(searchResponse.data![0]);
                if (searchResponse.data![0]) {
                    const values: any = searchResponse.data![0];
                    setIdUser(values.id)
                    form.setFieldValue(
                        "nombres",
                        `${values.first_name} ${values.second_surname}`
                    );
                }
            } else {
                messageApi.error(
                    searchResponse.error?.data?.message ||
                    "Hubo un error al crear al usuario, por favor inténtalo nuevamente.",
                    3.5
                );
            }
        }
    };

    const onFinishPlan = async (values: any) => {
        if (!loading) {

            //combo box plan por valor o porcentaje //health_company_plan
            //health_company_plan_currency // health_company_plan_percentage


            const sendPLan = {
                pension_scheme: values.cmbRegimen?.toLowerCase(),
                fund_quote: values.cmbFondo?.toLowerCase(),
                health_company: values.cmbCompaniSeg?.toLowerCase(),
                health_company_plan: values.monedaUF  || 0,
                health_company_plan_currency: parseInt(values.monedaIsapre || 0),
                health_company_plan_percentage: parseInt(values.monedaPorcent  || 0),
                afc: "normal",
                disability: false,
                invalidity: false
            }


            const loadingMessageKey = 'loading-message';
            messageApi.open({
                key: loadingMessageKey,
                type: 'loading',
                content: 'Registrado user...',
                duration: 0
            });
            setLoading(true);


            const loginResponse = await UserService.saveUser({ plan: sendPLan, tipo: 2, idUser:idUser });

            setLoading(false);

            messageApi.destroy(loadingMessageKey);

            if (loginResponse.success) {
                messageApi.success('Se registro con exito', 3.5);
                dispatch(AuthAction.signIn(loginResponse.data));
                // navigate('/');
            } else {
                messageApi.error(loginResponse.error?.data?.message || 'Hubo un error al crear al usuario, por favor inténtalo nuevamente.', 3.5);
            }
        }
    };
    const cleanForm = () => {
        form.resetFields();
    };
    const cleanFormPlan = () => {
        formPlan.resetFields();
    };


    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
        messageApi.error("Por favor corrija los errores mencionados", 3.5);
    };
    const loadCmbs = async () => {
   
   
        const tiposRegimenes = await UserService.cmbLoad("regimenes");
        if (tiposRegimenes?.data.length > 0)
            setTiposRegimenes(tiposRegimenes?.data);
        const tiposFondo = await UserService.cmbLoad("fondoCotizacion");
        if (tiposFondo?.data.length > 0) setTiposFondo(tiposFondo?.data);
        const tiposCompaniaSeg = await UserService.cmbLoad("fonasa");
        if (tiposCompaniaSeg?.data.length > 0)
            setTiposCompaniaSeg(tiposCompaniaSeg?.data);
    
    };
    useEffect(() => {
        loadCmbs();
    }, []);

    return (
        <>
            {contextHolder}
            <AppLayout title="Registrar Plan Usuario">
                <ProCard
                    style={{
                        minHeight: 350,
                        paddingLeft: "15%",
                    }}
                >
                    <Form
                        form={form}
                        name="login-form-rg"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            name="rut"
                            rules={[{ required: true, message: "El Rut es requerido" }]}
                        >
                            <Input className="login-form-rg-text" placeholder="Rut" />
                        </Form.Item>
                        <Form.Item className="buttons-style">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-form-button-rg"
                            >
                                BUSCAR
                            </Button>
                        </Form.Item>

                        <Form.Item className="buttons-style">
                            <Button
                                type="primary"
                                onClick={cleanForm}
                                className="login-form-button-rg"
                            >
                                Limpiar
                            </Button>
                        </Form.Item>
                        <Form.Item name="nombres">
                            <Input
                                disabled={true}
                                className="login-form-rg-text"
                                placeholder="Nombres"
                            />
                        </Form.Item>
                    </Form>

                    <Form
                        form={formPlan}
                        name="login-form-rg"
                        initialValues={{ remember: true }}
                        onFinish={onFinishPlan}
                        onFinishFailed={onFinishFailed}
                    >

                        <Form.Item
                            name='cmbRegimen'
                            rules={[
                                { required: true, message: 'Regimen' },
                            ]}>
                            <Select
                                placeholder="Seleccione Regimen"
                                options={tiposRegimenes}
                            />
                        </Form.Item>

                        <Form.Item
                            name='cmbFondo'
                            rules={[
                                { required: true, message: 'Fondo Cotizacion' },
                            ]}>
                            <Select
                                showSearch
                                placeholder="Seleccione Fondo Cotizacion"
                                optionFilterProp="children"
                                filterOption={(input, option: any) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={tiposFondo}
                            />
                        </Form.Item>


                        <Form.Item
                            name='cmbCompaniSeg'
                            rules={[
                                { required: true, message: 'Compañia ' },
                            ]}>
                            <Select
                                showSearch
                                placeholder="Seleccione Compañia Seguro"
                                optionFilterProp="children"
                                filterOption={(input, option: any) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={tiposCompaniaSeg}
                            />
                        </Form.Item>
                        <Form.Item
                            name="monedaUF"
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Ingrese Valor en Uf"
                            />
                        </Form.Item>

                        <Form.Item
                            name="monedaIsapre"
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Ingrese Valor en Pesos"
                            />
                        </Form.Item>

                        <Form.Item
                            name="monedaPorcent"
                        >
                            <Input
                                className='login-form-rg-text'
                                placeholder="Ingrese Valor en Porcentaje"
                            />
                        </Form.Item>


                        <Form.Item className="buttons-style">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-form-button-rg"
                            >
                                Crear Plan
                            </Button>
                        </Form.Item>

                        <Form.Item className="buttons-style">
                            <Button
                                type="primary"
                                onClick={cleanFormPlan}
                                className="login-form-button-rg"
                            >
                                Limpiar
                            </Button>
                        </Form.Item>

                    </Form>
                </ProCard>
            </AppLayout>
        </>
    );
};

export default SavePlan;
