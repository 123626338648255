import React, { ReactNode, useEffect, useState } from 'react';
import AppLayout from "../../components/Layouts/App/App";
import CountUp from 'react-countup';

import { Card, Col, Row, Spin, Statistic, Tag } from "antd";
import {
    UserOutlined
} from "@ant-design/icons";
import { DriverService } from "../Drivers/services/DriverService";
import { useSelector } from "react-redux";
import { HomeService } from "./services/HomeService";
import moment from "moment";
import { Carrier } from "../../models/Carrier";
import { UserService } from "../Users/services/UserService";
import FilterButton from "../../components/FilterButton/FilterButton";
import StoreFilterModal from "./components/StoreFilterModal";

const formatter = (value: number) => <CountUp end={value} separator="," />;

const Index = (): JSX.Element => {
    const authUser = useSelector((state: any) => state.auth);
    const [totalOrders, setTotalOrders] = useState(0);
    const [totalFinishedOrders, setTotalFinishedOrders] = useState(0);
    const [totalPendingOrders, setTotalPendingOrders] = useState(0);
    const [totalDrivers, setTotalDrivers] = useState(0);
    const [openStoreFilterModal, setOpenStoreFilterModal] = useState(false);
    const [carriers, setCarriers] = useState<Carrier[]>([]);
    const [selectedCarrierCodes, setSelectedCarrierCodes] = useState<string[]>([]);
    const [initComponent, setInitComponent] = useState(false);
    const [loadingOrderStatistics, setLoadingOrderStatistics] = useState(true);
    const [loadingDriverStatistics, setLoadingDriverStatistics] = useState(true);

    useEffect(() => {
        const init = async () => {
            const currentDay = moment().format('YYYY-MM-DD');

            setSelectedCarrierCodes(authUser.carriers);

            UserService.getCarriers().then((response) => {
                if (response.success) {
                    setCarriers(response.data);
                } else {
                    console.error("Get carriers", response);
                }
            });

            DriverService.getDrivers(authUser.token).then((response) => {
                if (response.success) {
                    setTotalDrivers(response.data.length);
                } else {
                    console.error("Get drivers", response);
                }
                setLoadingDriverStatistics(false);
            });

            const getStatisticsFromOrdersResponse = await HomeService.getStatisticFromOrders(currentDay, authUser.carriers);

            if (getStatisticsFromOrdersResponse.success) {
                setTotalOrders(getStatisticsFromOrdersResponse?.data?.totalOrders || 0);
                setTotalFinishedOrders(getStatisticsFromOrdersResponse?.data?.totalFinishedOrders || 0);
                setTotalPendingOrders(getStatisticsFromOrdersResponse?.data?.totalPendingOrders || 0);
            }

            setLoadingOrderStatistics(false);
            setInitComponent(true);
        };

        init();
    }, []);

    const getTitle = (): string | ReactNode => {
        if (!initComponent) {
            return 'Home';
        }

        const selectedCarriersObj = carriers.filter((carrier) => {
            return selectedCarrierCodes.includes(carrier.id);
        });

        if (selectedCarrierCodes.length > 0) {
            let tiendas = '';

            if (selectedCarriersObj.length <= 1) {
                tiendas = selectedCarriersObj.map((carrier) => carrier.name).join(', ');
            } else {
                tiendas = `${selectedCarrierCodes.length} tiendas seleccionadas`;
            }

            return (
                <>Home
                    <Tag color="blue" style={{ marginLeft: '5px' }}>
                        Tienda: {tiendas}
                        {
                            !authUser.carrierId
                            && (<FilterButton size="12px" action={() => { setOpenStoreFilterModal(true) }} />)
                        }
                    </Tag>
                </>
            );
        } else {
            return (
                <>Inicio

                </>
            );
        }
    }

    const filterStatistics = async (newCarrierCodes: string[]) => {
        setSelectedCarrierCodes(newCarrierCodes);
        setOpenStoreFilterModal(false);

        setLoadingOrderStatistics(true);

        const currentDay = moment().format('YYYY-MM-DD');
        const getStatisticsFromOrdersResponse = await HomeService.getStatisticFromOrders(currentDay, newCarrierCodes);

        if (getStatisticsFromOrdersResponse.success) {
            setTotalOrders(getStatisticsFromOrdersResponse?.data?.totalOrders || 0);
            setTotalFinishedOrders(getStatisticsFromOrdersResponse?.data?.totalFinishedOrders || 0);
            setTotalPendingOrders(getStatisticsFromOrdersResponse?.data?.totalPendingOrders || 0);
        }

        setLoadingOrderStatistics(false);
    }

    return (
        <AppLayout title={getTitle()}>

            <StoreFilterModal open={openStoreFilterModal} submit={filterStatistics} setOpen={setOpenStoreFilterModal} carriers={carriers} initValue={selectedCarrierCodes} />

            <Row gutter={16}>
                <Col span={6}>
                    <Spin spinning={loadingOrderStatistics}>
                        <Card bordered={false}>
                            <Statistic
                                title="Usuarios Registrados"
                                formatter={(formatter as any)}
                                value={4350}
                                precision={0}
                                valueStyle={{ color: '#000a86' }}
                            />
                        </Card>
                    </Spin>

                </Col>

            </Row>
        </AppLayout>
    );
};

export default Index;