import React from 'react';
import {HistoryOutlined, SearchOutlined} from "@ant-design/icons";
import {Order} from "../models/Order";

import './ContextMenu.css';

export interface ContextMenuSchema {
    record: any;
    visible: boolean;
    x: number;
    y:number;
}

const ContextMenu = ({ contextMenuPosition, showHistory, showDetail }: { contextMenuPosition: ContextMenuSchema, showHistory: (record: Order) => void, showDetail: (record: Order) => void }): JSX.Element => {
    const { record, visible, x, y } = contextMenuPosition;

    return (
        <>
            {
                (visible && record) && (
                    <ul className="popupcm" style={{left: `${x}px`, top: `${y}px`, padding: '5px'}}>
                        <span>Orden: <b>{record.orderClient}</b></span>
                        <hr style={{ border: '0', borderTop: '1px solid #c3c3c3'}}/>
                        <li onClick={() => { showDetail(record) }}><SearchOutlined style={{ marginRight: '3px' }} /> Ver detalles</li>
                        <li onClick={() => { showHistory(record) }}><HistoryOutlined style={{ marginRight: '3px' }} /> Ver historial</li>
                    </ul>
                )
            }
        </>
    );
};

export default ContextMenu;