import React, {useEffect, useState} from 'react';
import {Image} from "antd";
import {FileService} from "../../../services/FileService";

interface ImageHistoryContainerSchema {
    token: string;
    s3key?: string;
}

const loadingSrc = 'https://titaskdomain.s3.us-east-2.amazonaws.com/files/images/Spinner-1s-200px.gif';

const ImageHistoryContainer = ({ token, s3key }: ImageHistoryContainerSchema): JSX.Element => {
    const [imgSrc, setImgSrc] =  useState<string>(loadingSrc);

    useEffect(() => {
        const init = async () => {
            if(s3key) {
                const imgHrefBase64EncodedResponse = await FileService.getFileByKey(token, s3key);

                if(imgHrefBase64EncodedResponse.success) {
                    setImgSrc(`data:image/jpeg;charset=utf-8;base64,${imgHrefBase64EncodedResponse.data}`);
                }else{
                    console.log("No cargó la imagen con la s3key => ", s3key);
                }
            }
        };

        init();
    }, [ s3key ]);

    return (
        <>
            {
                s3key && (
                    <div style={{ marginBottom: '5px' }}>
                        <Image

                            width={80}
                            src={imgSrc}
                        />
                    </div>
                )
            }
        </>
    );
};

export default ImageHistoryContainer;